// 开料对接每个key对应的含义
export const cuttingDockFormTextObj = {
  single_double_station: '切换设备类型',
  autoSendStockCode: '工位1自动上料代码',
  evenAutoSendStockCode: '工位2自动上料代码',
  rollCode: '工位1翻面代码',
  evenRollCode: '工位2翻面代码',
  oddBeginCode: '工位1开始代码',
  oddAutoBaitingCode: '工位1下料代码',
  evenAutoBaitingCode: '工位2下料代码',
  evenBeginCode: '工位2开始代码',
  beginCodeF: '正面开始代码',
  beginCodeB: '反面开始代码',
  endCodeF: '正面结束代码',
  endCodeB: '反面结束代码',
  oddEndCode: '工位1结束代码',
  evenEndCode: '工位2结束代码',
  pauseCode: '暂停代码',
  firstKnifPreStartCodeInsert: '首刀提前',
  preStartCodeInsert: '第二把提前',
  oddVacuumPumpPauseCode: '真空泵1暂停代码',
  evenVacuumPumpPauseCode: '真空泵2暂停代码',
  first_front_annotation_code: '工位1双面加工正面注释代码',
  second_front_annotation_code: '工位2双面加工正面注释代码',
  mentaoji_enable: '锯片开关',
}

// 开料对接的value的值对应的中文映射
export const cuttingDockFormValueObj = {
  single_double_station: {
    single: '单工位',
    double: '双工位',
  },
  open_two_station_code: {
    true: '开启',
    false: '关闭',
  },
}

// 切割参数每个key对应的含义
export const cuttingParamsFormTextObj = {
  holeConcentrated: '孔集中面',
  apertureSlotConcentrated: '槽集中面',
  // 特殊孔槽自定义需求删除此选项
  // processChainAndHoleOnBigPlaneBack: '拉直器和铰链杯孔放到大板反面加工',
  isSpecialHoleSlot: '特殊孔槽自定义加工面',
  throughHoleSlotSameSide: '打穿孔槽所在面跟随该小板上未穿孔槽',
  prorate_holes_slots_of_both_side: '按比例分配正反面孔槽',
  hs_on_front_aft_shunt: '孔槽分流中设置的特殊孔槽优先排在大板正面功能', // 孔槽分流中设置的特殊孔槽优先排在大板正面功能
  cal_turn_num_aft_shunt:
    '启用孔槽分流后按照实际在雕刻机加工的反面孔槽计算翻版次数', // 在孔槽分流后计算翻版次数
  percentage_of_back_hole_slot: '反面孔槽占比',
  sideBoardPlacing: '靠边板槽放置',
  coedgeCutting: '共边切割',
  coedgeThick: '板件厚度小于等于',
  coedgeAccelerate: '共边加速',
  coedgeSpeed: '高速擦铣速度',
  startPosition: '工位起始点',
  xyReverse: 'XY轴互换',
  doubleStationReverse: '双工位起始点切换',
  planeOffset: '机台平面偏移',
  planeXOffset: '机台平面偏移-X',
  planeYOffset: '机台平面偏移-Y',
  planeZOffset: '机台平面偏移-Z',
  X_reference_swap: 'X轴坐标基准对调',
  x_reverse_reference_swap: '大板反面X坐标基准对调',
  y_reverse_reference_swap: '大板反面Y坐标基准对调',
  doubleDifferentSize: '双工位台面不同尺寸',
  stationOneMax: '工位一最大尺寸',
  stationTwoMax: '工位二最大尺寸',
  smallStationFirst: '大工位只加工小工位无法加工的大板',
  is_double_panel_four_trim: '双面板修边',
  trimSide: '修边方向',
  trimLSingle: '只修长边',
  new_trim: '启用新版修边规则',
  trimLMoment: '反面修边顺序',
  secondTrimValue: '正面再修边',
  double_panel_trim_short: '只修短边',
  surplusPosition: '余料摆放位置',
  zReference: '对刀基准面',
  idleZ: '空闲时Z高度(H)',
  knifeBasedOnPlank: '相对大板上表面高度',
  cutZ: '切透量Z深度(D)',
  throughTowSideToCut: '通孔打孔方式',
  through_slot_two_side_cut: '通槽打穿方式',
  cutDirection: '小板切割方向',
  clock_dir_switch: '自定义铣孔铣槽/内部异形切割方向',
  cut_internal_curve_dir: '内部异形切割方向',
  mill_hole_slot_dir: '铣孔铣槽方向',
  cuttingCurvedInterpolationCommand: '圆弧插补指令切割弧形板',
  noG17G18G19: '不生成G17/G18/G19面指令',
  arcInstruction: '圆弧插补指令前缀',
  arcCuttingCode: '圆弧切割用I、J表示',
  noOblique: '切割时斜下刀',
  back_trim_oblique: '反面修边斜下刀',
  obliqueSpeed: '斜下刀速度',
  knifeSpeed: '收刀速度',
  openknifeSpeed: '切割收刀减速',
  arcSpeed: '曲线加工速度',
  cuttingPreDistance: '下刀提前距离',
  angle_deceleration_enabled: '切割转角减速',
  angle_deceleration_dis: '转角前后距离',
  angle_deceleration_speed: '切割速度',
  angle_deceleration_list: '转弯减速',
  repeatedSlot: '槽切割两次',
  repeatedCut: '小板切割两次',
  minSafeLength: '长或宽小于',
  minSafeArea: '面积小于',
  millingSlotOverlapRate: '铣槽重叠率',
  autoClearFilletSlot: '铣槽时未拉穿的槽自动清理圆角',
  z_shape_processed_through_slot: '通槽上下Z字形铣槽',
  mill_z_slot_overlap_rate: 'Z字形铣槽切割重叠率',
  mill_slots_way: '铣槽走刀方式',
  secondCuttingDepth: '第二次切割深度',
  isLayeredCut: '开料分层切割设置',
  isLayeredCut: '不同板厚分层切割',
  newCutSequence: '新版切割算法',
  layered_slot_cutting: '铣槽拉槽分层切割',
  layered_slot_lt_knife_diameter: '刀径小于等于',
  layered_slot_gt_slot_deep: '槽深度大于等于',
  layered_slot_second_slot_deep: '第二层切割深度',
  trimmingFollowsLayeredCutting: '修边也跟随分层切割',
  longPlankCuttingEnable: '启用',
  cutSingleDoor: '门板',
  cutGeneralPlank: '厚度大于等于选中',
  generalPlankThick: '厚度大于等于',
  longPlankMinLength: '长边超过',
  longPlankShortMinLength: '短边',
  longPlankExpandedSize: '长边放量',
  longPlankShortExpandedSize: '短边放量',
  vacuumPumpPauseWay: '真空关闭方式',
  desMaterialSwitch: '指定材质开关',
  desMaterialList: '指定材质',
  decimal: '小数保留位数',
  plankSurfaceBufferCommand: '下料板件表面缓冲',
  hole_surface_buffer: '打孔板件表面缓冲',
  hole_slot_surface_buffer: '拉槽/铣孔/铣槽板件表面缓冲',
  buffer_way: '缓冲指令使用',
  boardGap: '排版间隙设置',
  highgloss_direction: '排版时小板高光面排在大板',
  waste_cutting: '废料自动截断开关',
  waste_size: '截断尺寸大于',
  waste_order: '切割顺序',
  ZK_datum_point_customize: 'Z扣斜孔加工旋转基准点开关',
  rotate_base_point: '旋转基准点(A0)',
  rotate_angle_direction: '旋转角度递增方向',
  is_first_trim_single_panel: '单面加工板正面生成修边路径且先修边',
  single_panel_trim_direction: '单面加工板正面生成修边路径且先修边方向',
  is_single_panel_four_trim: '单面板修边',
  mentaojiSize: '剩余尺寸小于值',
  mentaojiSizeSetting: '启用锯切',
  noVerticalSaw: '不生成生成切割路径',
  safe_length: '最小可吸住板件',
  strong_suction_zones: '真空强吸力位置',
  scattered_layout: '小板分散排版',
}
// 开料对接每个value对应的含义
export const cuttingParamsFormValueObj = {
  isSpecialHoleSlot: { true: '开启', false: '关闭' },
  desMaterialSwitch: { true: '开启', false: '关闭' },
  x_reverse_reference_swap: {
    true: '开启',
    false: '关闭',
  },
  y_reverse_reference_swap: {
    true: '开启',
    false: '关闭',
  },
  z_shape_processed_through_slot: {
    true: '开启',
    false: '关闭',
  },
  mentaojiSizeSetting: {
    true: '开启',
    false: '关闭',
  },
  noVerticalSaw: {
    true: '开启',
    false: '关闭',
  },
  holeConcentrated: {
    front: '正面',
    back: '反面',
  },
  apertureSlotConcentrated: {
    front: '正面',
    back: '反面',
  },
  is_double_panel_four_trim: {
    true: '修四边',
    false: '修两边',
  },
  trimSide: {
    topLeft: '左边、上边',
    bottomLeft: '左边、下边',
    topRight: '右边、上边',
    bottomRight: '右边、下边',
  },
  surplusPosition: {
    // { label: '左上角', value: 'topLeft' },
    // { label: '左下角', value: 'bottomLeft' },
    // { label: '右上角', value: 'topRight' },
    // { label: '右下角', value: 'bottomRight' },
    topLeft: '左上角',
    bottomLeft: '左下角',
    topRight: '右上角',
    bottomRight: '右下角',
  },
  sideBoardPlacing: {
    default: '系统默认',
    inside: '大板内侧',
    outside: '大板外侧',
  },
  trimLMoment: {
    切割开始: '反面最开始修边',
    切割结束: '反面孔槽加工后修边',
  },
  zReference: {
    counter_top: '台面',
    plank_surface: '大板表面',
  },
  throughTowSideToCut: {
    true: '翻面两次打穿',
    false: '一次打穿',
  },
  through_slot_two_side_cut: {
    true: '翻面两次打穿',
    false: '一次打穿',
  },
  vacuumPumpPauseWay: {
    after_one_plank: '单块长板切割后',
    after_all_plank: '整张大板切割后',
  },
  single_panel_trim_direction: {
    topLeft: '左边、上边',
    topRight: '右边、上边',
    bottomLeft: '左边、下边',
    bottomRight: '右边、下边',
  },
  is_single_panel_four_trim: {
    true: '修四边',
    false: '修两边',
  },
  mill_slots_way: {
    inner_to_out: '从内向外铣槽',
    out_to_inner: '从外向内铣槽',
  },
  highgloss_direction: {
    front: '正面',
    back: '反面',
  },
  rotate_base_point: {
    bottom: '大板前侧',
    top: '大板后侧',
    left: '大板左侧',
    right: '大板右侧',
  },
  rotate_angle_direction: {
    clockwise: '顺时针',
    counter_clockwise: '逆时针',
  },
  noOblique: {
    true: '开启',
    false: '关闭',
  },
  back_trim_oblique: {
    true: '开启',
    false: '关闭',
  },
  waste_order: {
    plank_first: '先切小板',
    waste_first: '先切废料',
  },
}
// 贴标设置每个key对应的含义
export const labellingSettingFormTextObj = {
  labelingMachine: '输出自动贴标文件',
  labelFormat: '贴标文件格式',
  rotateLabelSetting: '标签旋转设置',
  labelOverturn: '启用雕刻机翻面加工贴标模式',
  horizontal: '方向',
  criticalValue: '≤值',
  parallel_plank: '选择标签横边与小板固定边平行',
  parallel_approach: '标签横边',
  labelingStartPosition: '工位起始点',
  xDatumSwap: '坐标基准对调-x轴对调',
  yDatumSwap: '坐标基准对调-y轴对调',
  labelXOffset: 'X',
  labelYOffset: 'Y',
  xOffsetAfterLabelRotation: 'X',
  yOffsetAfterLabelRotation: 'Y',
  labelImageFormat: '贴标图片格式',
  labelImageFormatNew: '图片编码方式',
  label_picture_delimiter: '贴标图片名去掉下划线',
  label_resolution: '贴标图片分辨率',
  label_after_send_stock: '自动上料代码在贴标代码前面生成',
  num_auto_offset: '排版图序号自动补偿',
  front_labeling_start_num: '贴标序号起始数值',
  stockNumOffset: '大板序号补偿位数',
  label_image_name_separator: '分隔符',
  label_img_big_plank_folder: '标签图片根据大板分为不同文件夹',
  bmp_color_style: '导出bmp真彩/黑白',
  labelingDodge: '贴标自动避让',
  labelingOrder: '自动贴标顺序设置',
  xyReverse: 'xy轴互换',
  fileSuffix: '文件后缀',
  zzdCutLast: '主轴刀在最后加工',
  label_image_name_type: '贴标图片名',
}

export const labellingSettingFormValueObj = {
  labelingStartPosition: {
    top_left: '左上角',
    top_right: '右上角',
    bottom_left: '左下角',
    bottom_right: '右下角',
  },
  horizontal: {
    H: '板材横边≤',
    V: '板材竖边≤',
    HV: '板材横/竖边≤',
  },
  parallel_approach: {
    parallel_height: '与小板长度保持平行',
    parallel_width: '与小板宽度保持平行',
  },
  labelImageFormat: {
    jpg: 'jpg',
    bmp: 'bmp',
  },
  bmp_color_style: {
    0: '真彩',
    1: '黑白',
  },
  labelingOrder: {
    toTop: '按照排版结果从下往上贴标',
    toBottom: '按照排版结果从上往下贴标',
    cutOrder: '按照开料顺序贴标',
  },
}

export const gangDrillFormTextObj = {
  enabled: '启用排钻包',
  prefix: '排钻前缀',
  suffix: '排钻后缀',
  beginCode: '排钻启动代码',
  endCode: '排钻结束代码',
  drill_process_mode: '排钻加工模式',
  drill_benchmark: '组合下刀基准',
  gangDrillCombinationEnable: '排钻组合下刀',
  gangDrillCombinationMainPrefix: '主孔排钻编号前缀',
  gangDrillCombinationMainSuffix: '主孔排钻编号后缀',
  gangDrillCombinationComment: '组合注释',
  gangDrillCombinationuCommentFirstSign: '首次启用注释',
}

export const NcExportSettingFormTextObj = {
  doubleStationSortName: '按双工位自动排序',
  is_generate_process_sort_number: '下料文件名生成加工序号',
  stockNumLength: '大板序号位数补偿到',
  labelMachineName: '标签机开头',
  machineCenterName: '加工中心开头',
  front_info: '正面',
  back_info: '反面',
  nameArr: '自定义文件名(格式)', // 这个是新增加的属性
  hasDelimiter: '分隔符',

  doubleEdgeCombine: '正反面合并',
  bEnd1: '反面结束代码',
  fBegin1: '正面开始代码',
  displayfbInfo: '文件名显示正反面信息',

  doubleStationCombine: '双工位合并',
  bBegin2: '反面开始代码',
  bEnd2: '反面结束代码',
  fBegin2: '正面开始代码',
  fEnd2: '正面结束代码',

  singleStationCombine: '单工位合并',
  bBegin3: '反面开始代码',
  bEnd3: '反面结束代码',
  fBegin3: '正面开始代码',
  fEnd3: '正面结束代码',

  beforePlankAddInfo: '开料文件开头加入当前大板件信息',
  beforePlankAddCustomizeInfo: '大板信息',
  beforePlank: '开料文件开头加入工位代码',
  beforeStartKnife: '开料文件换刀前加入工位代码',

  always_back_nc: '反面不加工时仍然生成含有反面结束代码的反面NC文件',
  alwaysZ: 'Z坐标补充',
  semicolon: '每行NC末尾加分号',
  exportUsingBanlu: '文件名',
  fileExtensionName: '输出雕刻机文件格式',
  exportFormat: '文件名格式',
  exportFormatAnnotation: '星辉E2-9注释',
  fileNameType: '导出文件类型',
  useCategoryFolder: '根据大板颜色、厚度、材质归为不同文件夹(贴标文件一起)',
  same_size_sheet_cutting_files: '同尺寸大板下料文件放在同一个文件夹',
  label_image_in_nc_folder: '贴标图片跟跟随贴标文件放在NC文件夹里',
  GCommandShorthand: '加工G指令简写',
  z_to_w_normal: 'Z轴转W轴普通刀具',
  z_to_w_gang_drill: 'Z轴转W轴排钻刀具',
  platform: '换行符格式设置',
  fileEncoding: '文件编码格式设置',
  fileSuffix: '文件后缀',
  genSimpleLabelBarcode: '生成简易格式标签条码',
  uniqueBarCode: '板件条码唯一',
  uniqueBarCodeForPack: '板件条码唯一(打包专用)',
  settingKey: '应急码',
  param1: '参数1',
  param2: '参数2',
  param3: '参数3',
  stationEndCodeBeforeEndCodeB: '工位结束代码在反面结束代码前面生成',
  output56FDrillSortByCode: '打孔文件按不同订单分文件夹单独放置',
  handleSlope: '输出单独文件加工斜边款海棠角和斜边拉手',
}

export const NcExportSettingFormValueObj = {
  exportFormat: {
    default: '默认文件名',
    xinghui: '星辉设备专用文件名',
  },
  same_size_sheet_cutting_files: {
    false: '关闭',
    true: '开启',
  },
  label_image_in_nc_folder: {
    false: '关闭',
    true: '开启',
  },
  exportFormatAnnotation: {
    xinghui: true,
    '': false,
  },
  fileEncoding: {
    ['utf-8']: 'UTF-8',
    utf8bom: '带bom的UTF-8',
    gbk: 'ansi',
  },
}

export const AioMachineSettingTextObj = {
  enable_integration_device: '输出一体机加工文件',
  yitiji_machine_name: '文件格式',
  trimming_knife_diameter: '修边刀具直径',
  is_display_sample: '显示数据样例',
  anc_begin_code: '程序开始代码',
  anc_end_code: '程序结束代码',
}

export const AioMachineSettingValueObj = {
  anc_setting: {
    anc_begin_code: '程序开始代码',
    anc_end_code: '程序结束代码',
  },
  aio_fixture_setting: {
    aio_avoid_fixture: '孔槽避让夹手位置',
    aio_fixture_length: '夹手长度',
    aio_fixture_width: '夹手宽度',
    aio_first_fixture_height: '夹手1距边',
    aio_second_fixture_height: '夹手2距边',
    aio_fixture_direction: '夹手方向',
  },
}

export const OneDragTwoEngravingTextObj = {
  enableOneForTwoDevice: '输出一拖二雕刻机加工文件',
  oneForTwoMachineName: '文件格式',

  trimSide: '修边方向',
  trimLSingle: '只修长边',
  trimLMoment: '反面修边顺序',
  secondTrimValue: '正面再修边',
  new_trim: '启用新版修边规则',

  one_for_two_is_double_panel_four_trim: '双面板修边',
  one_for_two_double_panel_trim_short: '只修短边',
  one_for_two_trim: '修边设置开关',
  one_for_two_trim_side: '修边方向',
  one_for_two_trim_single: '只修长边',
  one_for_two_trim_moment: '反面修边顺序',
  one_for_two_second_trim_value: '正面再修边',
  one_for_two_new_trim: '启用新版修边规则',
  one_for_two_single_panel_trim: '单面加工板正面生成修边路径且先修边',
  one_for_two_single_panel_trim_direction:
    '单面加工板正面生成修边路径且先修边方向',
  one_for_two_is_single_panel_four_trim: '单面板修边',
  enable_one_for_two_start_position: '工位起始点',
  one_for_two_start_position: '工位起始点',
  secondAutoSendStockCode: '自动上下料代码',
  secondPauseCode: '暂停代码',
  secondOddBeginCode: '工位1开始代码',
  secondEvenBeginCode: '工位2开始代码',
  secondBeginCodeF: '正面开始代码',
  secondBeginCodeB: '反面开始代码',
  secondEndCodeF: '正面结束代码',
  secondEndCodeB: '反面结束代码',
  secondRollCode: '工位1翻面代码',
  secondEvenRollCode: '工位2翻面代码',
  secondOddEndCode: '工位1结束代码',
  secondEvenEndCode: '工位2结束代码',
}

export const OneDragTwoEngravingValueObj = {
  one_for_two_single_panel_trim_direction: {
    topLeft: '左边、上边',
    topRight: '右边、上边',
    bottomLeft: '左边、下边',
    bottomRight: '右边、下边',
  },
  one_for_two_trim_side: {
    topLeft: '左边、上边',
    topRight: '右边、上边',
    bottomLeft: '左边、下边',
    bottomRight: '右边、下边',
  },
  one_for_two_is_double_panel_four_trim: {
    true: '修四边',
    false: '修两边',
  },
  one_for_two_is_single_panel_four_trim: {
    true: '修四边',
    false: '修两边',
  },
}

const switchObj = {
  true: '开启',
  false: '关闭',
}

export const SawEngravingTextObj = {
  mentaoji_enable: '启用锯切雕刻机',
  noVerticalSaw: '不生成纵向锯片切割路径',
  sawToTrim: '修边',
  sawToCut: '切割下料',
  mentaojiSizeSetting: '锯片使用条件',
  mentaojiSize: '锯片使用条件余料剩余尺寸值',
  sawThrough: '切割方式',
  sawThroughSize: '切割方式余料剩余尺寸值',
  sawToLayout: '启用锯片厚度排版',
  edgeNoSaw: '是否开启',
  edgeNoSawSide: '具体边',
  firstCutSurplus: '是否开启',
}

export const SawEngravingValueObj = {
  mentaoji_enable: switchObj,
  noVerticalSaw: switchObj,
  mentaojiSizeSetting: {
    false: '有可用路径便使用',
    true: '余料剩余尺寸小于多少时才使用',
  },
  sawThrough: {
    false: '一刀拉穿大板',
    true: '余料剩余尺寸小于多少时才直接一刀拉穿',
  },
  sawToLayout: switchObj,
  edgeNoSaw: switchObj,
  firstCutSurplus: switchObj,
  edgeNoSawSide: {
    top: '上边',
    bottom: '下边',
    left: '左边',
    right: '右边',
  },
}
