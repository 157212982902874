<template>
  <div class="labelling-setting h100">
    <div class="h100 y-auto">
      <div class="head-title">
        <span>{{ $t('cuttingDock.labelingSettings.output') }}</span>
        <a-switch
          default-checked
          v-model="formData.labelingMachine"
          id="engraving_setting_labelMachine_switch"
          style="margin-left: 68px"
        ></a-switch>
      </div>
      <div v-show="formData.labelingMachine">
        <div class="label-file-format mt16">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.specification')
          }}</span>
          <!-- <div class="radio-box">
            <a-radio-group v-model="formData.labelFormat">
              <a-radio v-for="(item, index) in fileFormatList" :key="index" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </div> -->
          <div class="radio-box" style="position: relative">
            <span class="base-title-label"
              >{{ $t('cuttingDock.labelingSettings.specification') }}：</span
            >
            <a-select
              style="width: 280px"
              v-model="formData.labelFormat"
              @change="changeLabelingFormat"
              id="engraving_setting_labelFormat_select"
              show-search
              showArrow
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body
                }
              "
              :filter-option="
                (input, option) =>
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .trim()
                    .includes(input.toLowerCase())
              "
            >
              <a-select-option
                v-for="(label, index) in allLabelingList"
                :key="label.labelFormat"
                :value="label.labelFormat"
                :id="`engraving_setting_labelFormat_option_${index}`"
                :title="label.modelName"
                class="all-labeling"
              >
                {{ label.modelName }}
              </a-select-option>
            </a-select>
            <span v-if="labelNotOnline" class="not-online-info">{{
              $t('cuttingDock.labelingSettings.templateOffline')
            }}</span>
          </div>
        </div>
        <div class="auto-feeding-box">
          <span class="base-title-label"
            >{{ $t('cuttingDock.labelingSettings.codeFrontGenerate') }}：</span
          ><a-switch
            v-model="formData.label_after_send_stock"
            id="engraving_setting_label_after_send_stock_checkbox"
          ></a-switch>
        </div>
        <!-- 自动贴标设置 -->
        <div class="auto-label-setting">
          <div class="flex title">
            <span class="fs20 color-0 bold">{{
              $t('cuttingDock.labelingSettings.autolabel')
            }}</span>
            <span class="inline-block ml10 auto-label-tip fs16 color-o-4"
              >({{ $t('cuttingDock.labelingSettings.customTemp') }})</span
            >
          </div>
          <div style="padding: 18px 24px 24px 24px">
            <span class="base-title-label"
              >{{ $t('cuttingDock.labelingSettings.autolabel') }}：</span
            >
            <a-select
              style="width: 280px"
              v-model="formData.labelingOrder"
              @change="handleChangeRadio"
              id="engraving_setting_label_sort_select"
              showArrow
            >
              <a-select-option
                v-for="(item, index) in labelingOrderList"
                :key="item.value"
                :value="item.value"
                :id="`engraving_setting_label_sort_option_${index}`"
              >
                {{ $t(item.label) }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <!-- 孔槽避让 -->
        <div class="flex labeling-dodge">
          <span class="fs20 color-o-9 bold" style="margin-right: 124px">{{
            $t('cuttingDock.labelingSettings.labelDodge')
          }}</span>
          <a-switch
            v-model="formData.labelingDodge"
            id="engraving_setting_dodge_switch"
          ></a-switch>
        </div>
        <div>
          <span class="head-title labeling-setting">{{
            $t('cuttingDock.labelingSettings.rollLabelling')
          }}</span>
          <div class="auto-feeding-box flex">
            <span class="base-title-label"
              >{{
                $t(
                  'cuttingDock.labelingSettings.flipSetting.engravingFlipProcess'
                )
              }}：</span
            >
            <div class="flex flex-dir--top">
              <a-switch
                v-model="formData.labelOverturn"
                style="width: 44px"
              ></a-switch>
              <i class="tip mt4 fs14">{{
                $t('cuttingDock.labelingSettings.flipSetting.flipTip')
              }}</i>
            </div>
          </div>
        </div>
        <div class="tag-setting">
          <span class="head-title"
            >{{ $t('cuttingDock.labelingSettings.rotateSetting.title') }}
            <i class="tip ml10"
              >({{
                $t('cuttingDock.labelingSettings.rotateSetting.rotateTip')
              }})</i
            >
          </span>
          <div style="padding: 18px 24px 24px 24px">
            <div class="flex">
              <span class="base-title-label">
                {{ $t('cuttingDock.labelingSettings.settingValue') }}：
              </span>
              <div class="flex flex-dir--top">
                <a-switch
                  v-model="formData.rotateLabelSetting.rotateLabel"
                  id="engraving_setting_rotateLabel_switch"
                  style="width: 44px"
                ></a-switch>
                <i class="color-o-4 mt4">
                  {{ $t('cuttingDock.labelingSettings.enabelTip') }}
                </i>
                <div
                  v-show="
                    isShowSecondaryCatalog ||
                    formData.rotateLabelSetting.rotateLabel
                  "
                  style="margin-top: 24px"
                >
                  <a-select
                    style="width: 160px"
                    v-model="formData.rotateLabelSetting.horizontal"
                    :disabled="!formData.rotateLabelSetting.rotateLabel"
                    id="engraving_setting_label_sort_select"
                    showArrow
                  >
                    <a-select-option
                      v-for="(item, index) in horizontalList"
                      :key="item.value"
                      :value="item.value"
                      :id="`engraving_setting_label_sort_option_${index}`"
                    >
                      {{ $t(item.label) }}
                    </a-select-option>
                  </a-select>
                  <span class="ph10">≤</span>
                  <a-input
                    suffix="mm"
                    ref="rotateInput"
                    id="engraving_setting_rotateLabelSetting_criticalValue_input"
                    @change="
                      checkInout(
                        formData.rotateLabelSetting.criticalValue,
                        formData,
                        ['rotateLabelSetting', 'criticalValue'],
                        '4-2',
                        '0.01'
                      )
                    "
                    v-model="formData.rotateLabelSetting.criticalValue"
                    :disabled="!formData.rotateLabelSetting.rotateLabel"
                    style="width: 138px"
                  ></a-input>
                </div>
                <div
                  class="pt10"
                  v-show="
                    isShowSecondaryCatalog ||
                    formData.rotateLabelSetting.rotateLabel
                  "
                >
                  <img src="@/assets/tagRota1.png" alt="加载错误" />
                </div>
              </div>
            </div>
            <div class="flex" style="margin-top: 32px">
              <span class="base-title-label">
                {{ $t('cuttingDock.labelingSettings.fixedHori') }}：
              </span>
              <div class="flex flex-dir--top">
                <a-switch
                  v-model="formData.lable_parallel_setting.parallel_plank"
                  id="engraving_setting_rotateLabel_switch"
                  style="width: 44px"
                ></a-switch>
                <i class="fs14 color-o-4 mt4">
                  {{ $t('cuttingDock.labelingSettings.fixedHoriTip') }}
                </i>
                <span
                  class="head-title"
                  style="
                    padding: 24px 0 10px 0;
                    font-weight: 400;
                    font-size: 14px;
                  "
                  v-show="
                    isShowSecondaryCatalog ||
                    formData.lable_parallel_setting.parallel_plank
                  "
                >
                  {{ $t('cuttingDock.labelingSettings.tagHori') }}=
                  <a-select
                    style="width: 180px"
                    v-model="formData.lable_parallel_setting.parallel_approach"
                    @change="changeLabelingFormat"
                    id="engraving_setting_tagHorSide_select"
                    class="ml8"
                    showArrow
                    :options="tagHorSideArr"
                    :disabled="!formData.lable_parallel_setting.parallel_plank"
                  >
                  </a-select>
                </span>
                <div
                  class="pt10"
                  v-show="
                    isShowSecondaryCatalog ||
                    formData.lable_parallel_setting.parallel_plank
                  "
                >
                  <img src="@/assets/tagSign1.png" alt="加载错误" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="XYZ-offset">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.countertopSetting.title')
          }}</span>
          <div class="XYZ-offset-box">
            <div class="x-point flex">
              <span class="base-title-label"
                >{{
                  $t(
                    'cuttingDock.labelingSettings.countertopSetting.startPoint'
                  )
                }}：</span
              >
              <div>
                <div class="flex flex-cross--center">
                  <m-machine-pos
                    :position="labelingStartPosition"
                    @onPositionChange="handleChangeStartPos"
                  >
                  </m-machine-pos>
                  <span class="ml8 color-o-4">{{
                    labelingStartPositionTip
                  }}</span>
                </div>
                <div class="mt5 mb5" style="color: rgba(0, 0, 0, 0.4)">
                  {{ $t('preLayoutSetting.click')
                  }}<span
                    style="
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      flex-shrink: 0;
                      background: #d9d9d9;
                    "
                  ></span
                  >{{ $t('preLayoutSetting.grayStart') }}，<span
                    style="
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      flex-shrink: 0;
                      background: #18a8c7;
                    "
                  ></span
                  >{{ $t('preLayoutSetting.currentStart') }}
                </div>
              </div>
            </div>
          </div>
          <div class="XYZ-offset-box">
            <div class="x-point">
              <span class="base-title-label"
                >{{
                  $t(
                    'cuttingDock.labelingSettings.countertopSetting.baseExchange'
                  )
                }}：</span
              >
              <a-checkbox
                v-model="formData.labelingCountertopSetting.xDatumSwap"
                id="engraving_setting_xDatumSwap_checkbox"
                ><span id="engraving_setting_xDatumSwap_checkbox_label">{{
                  $t('cuttingDock.labelingSettings.countertopSetting.exchangeX')
                }}</span></a-checkbox
              >
              <a-checkbox
                v-model="formData.labelingCountertopSetting.yDatumSwap"
                id="engraving_setting_yDatumSwap_checkbox"
                ><span id="engraving_setting_yDatumSwap_checkbox_label">{{
                  $t('cuttingDock.labelingSettings.countertopSetting.exchangeY')
                }}</span></a-checkbox
              >
            </div>
          </div>
        </div>
        <div class="XYZ-offset">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.offset')
          }}</span>
          <div class="XYZ-offset-box" style="align-items: normal">
            <span class="base-title-label mt5" style="color: rgba(0, 0, 0, 0.9)"
              >{{ $t('cuttingDock.labelingSettings.offset') }}：</span
            >
            <div>
              <div class="flex">
                <div class="x-point">
                  <span>X：</span>
                  <a-input
                    :placeholder="$t('common.inputPlaceholder')"
                    v-model="formData.labelXOffset"
                    id="engraving_setting_labelXOffset_input"
                  ></a-input>
                </div>
                <div class="y-point">
                  <span>Y：</span>
                  <a-input
                    :placeholder="$t('common.inputPlaceholder')"
                    v-model="formData.labelYOffset"
                    id="engraving_setting_labelYOffset_input"
                  ></a-input>
                </div>
              </div>
              <span class="color-o-4 mt4 inline-block">{{
                $t('cuttingDock.labelingSettings.offsetTip')
              }}</span>
            </div>
          </div>
        </div>
        <!-- 标签旋转偏移 -->
        <div class="XYZ-offset">
          <div class="flex labeling-dodge">
            <span class="fs20 color-0 bold mr16">{{
              $t('cuttingDock.labelingSettings.label_rotate_offset')
            }}</span>
            <a-switch
              v-model="formData.offsetAfterLabelRotation"
              id="labelling_setting_tagRotateOffset_switch"
              style="margin-right: 10px; margin-left: 48px"
            ></a-switch>
            <span class="color-o-4"
              >({{
                $t('cuttingDock.labelingSettings.label_rotate_offset_tip')
              }})</span
            >
          </div>
          <div
            v-show="formData.offsetAfterLabelRotation"
            class="XYZ-offset-box pb4"
          >
            <span class="base-title-label mt2" style="color: rgba(0, 0, 0, 0.9)"
              >{{
                $t('cuttingDock.labelingSettings.label_rotate_offset')
              }}：</span
            >
            <div class="x-point">
              <span>X：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.xOffsetAfterLabelRotation"
                id="engraving_setting_xOffsetAfterLabelRotation_input"
              ></a-input>
            </div>
            <div class="y-point">
              <span>Y：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.yOffsetAfterLabelRotation"
                id="engraving_setting_yOffsetAfterLabelRotation_input"
              ></a-input>
            </div>
          </div>
          <span
            v-show="checkTagRotateOffset"
            style="margin-left: 267px; color: red"
          >
            {{ $t('cuttingDock.labelingSettings.label_rotate_offset_err_tip') }}
          </span>
        </div>
        <div class="image-format">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.imgSetting.title')
          }}</span>
          <div class="image-format-box">
            <span class="base-title-label">{{
              $t('cuttingDock.labelingSettings.imgSetting.suffix')
            }}</span>
            <a-radio-group v-model="formData.labelImageFormat">
              <a-radio
                value="jpg"
                id="engraving_setting_labelImageFormat_jpg_radio"
                ><span id="engraving_setting_labelImageFormat_jpg_radio_label"
                  >jpg</span
                ></a-radio
              >
              <a-radio
                value="bmp"
                id="engraving_setting_labelImageFormat_bmp_radio"
                ><span id="engraving_setting_labelImageFormat_bmp_radio_label"
                  >bmp</span
                ></a-radio
              >
              <a-radio
                value="png"
                id="engraving_setting_labelImageFormat_png_radio"
                ><span id="engraving_setting_labelImageFormat_png_radio_label"
                  >png</span
                ></a-radio
              >
            </a-radio-group>
          </div>
          <div class="image-format-box">
            <span class="base-title-label">{{
              $t('cuttingDock.labelingSettings.imgSetting.code')
            }}</span>
            <a-radio-group v-model="formData.labelImageFormatNew">
              <a-radio
                value="png"
                id="engraving_setting_labelImageFormatNew_png_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_png_radio_label"
                  >png</span
                ></a-radio
              >
              <a-radio
                value="jpeg"
                id="engraving_setting_labelImageFormatNew_jpeg_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_jpeg_radio_label"
                  >jpeg</span
                ></a-radio
              >
              <a-radio
                value="bmp"
                v-if="isShowBmpRadio"
                id="engraving_setting_labelImageFormatNew_bmp_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_bmp_radio_label"
                  >bmp（{{
                    $t('cuttingDock.labelingSettings.imgSetting.slowDown')
                  }}）</span
                ></a-radio
              >
            </a-radio-group>
            <div style="margin-left: 246px" class="color-o-4 mt4">
              {{ $t('cuttingDock.labelingSettings.imgSetting.codeTip') }}
              <div v-if="isBmpExport" class="mt10">
                <a-radio-group v-model="formData.bmp_color_style">
                  <a-radio
                    :value="0"
                    id="engraving_setting_is1bitBmp_radio_false"
                    ><span id="engraving_setting_is1bitBmp_radio_false_label">{{
                      $t('cuttingDock.labelingSettings.imgSetting.color')
                    }}</span></a-radio
                  >
                  <a-radio
                    :value="1"
                    id="engraving_setting_is1bitBmp_radio_true"
                    ><span id="engraving_setting_is1bitBmp_radio_true_label">{{
                      $t('cuttingDock.labelingSettings.imgSetting.bit')
                    }}</span></a-radio
                  >
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="image-format-box">
            <span class="base-title-label"
              >{{
                $t('cuttingDock.labelingSettings.imgSetting.resolution')
              }}：</span
            >
            <el-input
              :placeholder="
                $t(
                  'cuttingDock.labelingSettings.imgSetting.resolutionPlaceholder'
                )
              "
              size="small"
              v-model="formData.label_resolution[0]"
              @input="resolutionChange($event, 0)"
              maxlength="4"
              id="engraving_setting_label_resolution_input_1"
            ></el-input>
            ×
            <el-input
              :placeholder="
                $t(
                  'cuttingDock.labelingSettings.imgSetting.resolutionPlaceholder'
                )
              "
              size="small"
              v-model="formData.label_resolution[1]"
              @input="resolutionChange($event, 1)"
              maxlength="4"
              id="engraving_setting_label_resolution_input_2"
            ></el-input>
          </div>
          <div>
            <!-- <m-tag-lake
              :tag-list="tagList"
              :values="formData.label_image_name_type ?? []"
              @onChange="handleTagChange"
            ></m-tag-lake> -->
            <div class="image-format-box flex">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.labelImgName')
                }}：</span
              >
              <div>
                <span class="color-o-4">
                  {{ $t('cuttingDock.labelingSettings.imgSetting.labelTip1') }}
                  <p>
                    {{
                      $t('cuttingDock.labelingSettings.imgSetting.labelTip2')
                    }}！
                  </p>
                </span>
                <!-- <a-transfer
                  :data-source="tagList"
                  :titles="['数据标签池', '显示字段']"
                  :target-keys="formData.label_image_name_type ?? []"
                  :lazy="false"
                  :render="(item) => item.title"
                  @change="handleTagChange"
                /> -->
                <m-data-tag
                  :tag-list="tagList"
                  :values="formData.label_image_name_type ?? []"
                  @onChange="handleTagChange"
                ></m-data-tag>
              </div>
            </div>
            <div class="image-format-box">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.delimiter')
                }}：</span
              >
              <a-input
                class="split-char"
                @input="handleinputSplitChar"
                v-model="formData.label_image_name_separator"
              ></a-input>
              <div class="color-a mt4" style="margin-left: 246px">
                {{ $t('cuttingDock.labelingSettings.imgSetting.inputTip') }}
              </div>
            </div>
            <div class="image-format-box">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.numStart')
                }}：</span
              >
              <a-input
                id="labellingSetting_start_number_input"
                class="split-char"
                @blur="handleInputBlur"
                @input="handleInputNumber"
                v-model="formData.front_labeling_start_num"
              ></a-input>
            </div>
            <div class="image-format-box">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.example')
                }}：</span
              >
              <span style="white-space: pre">{{ exampleName }}</span>
            </div>
            <div class="image-format-box">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.stockNumOffset')
                }}：</span
              >
              <a-input-number
                id="stockNum_offset_number_input"
                class="split-char"
                :min="1"
                :max="99"
                :controls="false"
                v-model="formData.stockNumOffset"
                @change="handlerEditStockNumOffset"
              ></a-input-number>
              <span class="color-a">{{
                $t('cuttingDock.labelingSettings.paibanImgSetting.figure')
              }}</span>
              <span class="color-a"
                >（{{
                  $t('cuttingDock.labelingSettings.paibanImgSetting.inputTip')
                }}）</span
              >
            </div>
            <div class="image-format-box">
              <span class="base-title-label"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.divideFolders')
                }}：</span
              >
              <a-switch
                id="labellingSetting_big_plank_folder_checkbox"
                v-model="formData.label_img_big_plank_folder"
                :disabled="obj.label_image_in_nc_folder"
              ></a-switch>
              <div class="color-a mt4" style="margin-left: 246px">
                {{ $t('cuttingDock.labelingSettings.imgSetting.foldersTip1') }}
                <p>
                  {{
                    $t('cuttingDock.labelingSettings.imgSetting.foldersTip2')
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="image-format-box flex" v-if="!isHideLabelingInsert">
            <span class="base-title-label"
              >{{
                $t('cuttingDock.labelingSettings.imgSetting.stickIntoFolders')
              }}：</span
            >
            <div>
              <a-switch
                id="labellingSetting_file_insert_checkbox"
                :disabled="!formData.label_img_big_plank_folder"
                v-model="formData.labeling_file_insert"
              ></a-switch>
              <div class="color-o-4 mt4">
                {{ $t('cuttingDock.labelingSettings.imgSetting.stickTip1') }}
                <p class="mb0">
                  {{ $t('cuttingDock.labelingSettings.imgSetting.stickTip2') }}
                </p>
                <span
                  >{{ $t('cuttingDock.labelingSettings.imgSetting.stickTip3') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paiban-image" v-show="formData.labelingMachine">
        <span class="head-title">{{
          $t('cuttingDock.labelingSettings.paibanImgSetting.title')
        }}</span>
        <div class="auto-arrange-umber">
          <span class="base-title-label"
            >{{
              $t('cuttingDock.labelingSettings.paibanImgSetting.reparation')
            }}：</span
          >
          <a-input-number
            id="labellingSetting_autoArrangeNumber_input"
            v-model="formData.layoutImageSetting.num_auto_offset"
            :min="1"
            :max="99"
          ></a-input-number>
          <span>{{
            $t('cuttingDock.labelingSettings.paibanImgSetting.figure')
          }}</span>
          <p class="color-a mt4" style="margin-left: 246px">
            {{ $t('cuttingDock.labelingSettings.paibanImgSetting.inputTip') }}
          </p>
        </div>
        <div class="paiban-image-box">
          <div
            v-for="(item, index) in formData.layoutImageSetting.layoutImage"
            :key="index"
          >
            <div class="paiban-form-item">
              <span class="base-title-label"
                >{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.export'
                  )
                }}{{ index + 1 }}：</span
              >
              <div>
                <a-checkbox
                  v-model="item.enable"
                  id="labellingSetting_enable_checkbox"
                  class="mb16"
                ></a-checkbox>
                <div v-show="isShowSecondaryCatalog || item.enable">
                  <div class="paiban-form-item paiban-before">
                    <span class="title">{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.prefix'
                      )
                    }}</span>
                    <a-input
                      v-model="item.imageNamePrefix"
                      :max="20"
                      id="labellingSetting_imageNamePrefix_input"
                    ></a-input>
                  </div>
                  <div class="paiban-form-item paiban-size">
                    <span class="title">{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.size'
                      )
                    }}</span>
                    <div>
                      <a-input
                        v-model="item.imageSize[0]"
                        @input="paibanImgLimit(item, 0)"
                        id="labellingSetting_paibanImgLimit0_input"
                      ></a-input>
                      X
                      <a-input
                        v-model="item.imageSize[1]"
                        @input="paibanImgLimit(item, 1)"
                        id="labellingSetting_paibanImgLimit1_input"
                      ></a-input>
                    </div>
                  </div>
                  <div class="paiban-form-item">
                    <span class="title">{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.format'
                      )
                    }}</span>
                    <a-radio-group v-model="item.imageFormat">
                      <a-radio
                        value="jpg"
                        id="labellingSetting_imageFormat_jpg_radio"
                        >jpg</a-radio
                      >
                      <a-radio
                        value="bmp"
                        id="labellingSetting_imageFormat_bmp_radio"
                        >bmp</a-radio
                      >
                    </a-radio-group>
                  </div>
                  <div class="paiban-form-item" v-if="item.way != 3">
                    <span class="title">{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.directional'
                      )
                    }}</span>
                    <a-radio-group v-model="item.imageDirection">
                      <a-radio
                        value="vert"
                        id="labellingSetting_imageDirection_vert_radio"
                        >{{
                          $t(
                            'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.vertical'
                          )
                        }}</a-radio
                      >
                      <a-radio
                        value="horiz"
                        id="labellingSetting_imageDirection_horiz_radio"
                        >{{
                          $t(
                            'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.horizontal'
                          )
                        }}</a-radio
                      >
                    </a-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import newContent from '@/components/newContent.vue'
import { buryPointFunc } from '@/util/buryPointDeal'
import { verifyInputNumber } from '@/util/commonFuncs'
import mMachinePos from '@/views/preLayoutSetting/components/m-machine-pos.vue'
import { mapState } from 'vuex'

import MDataTag from './m-data-tag.vue'
import MTagLake from './m-tag-lake.vue'

export default {
  components: {
    MTagLake,
    newContent,
    mMachinePos,
    MDataTag,
  },
  props: {
    obj: {
      type: [Array, Object],
      default: () => ({}),
    },
    paibanImgScale: {
      type: Number,
      default: 2,
    },
    allLabelingList: {
      type: [Array, Object],
      default: () => [],
    },
    labelNotOnline: {
      type: Boolean,
      default: false,
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      vv: true,
      fileFormatList: [
        { name: '默认格式', value: 'bluen' },
        { name: '南兴格式', value: 'nanxing' },
        { name: '霍尼韦尔格式', value: 'honeywell' },
        { name: '星辉格式', value: 'xinghui' },
        { name: '豪德格式', value: 'haode' },
        { name: '极东格式', value: 'jidong' },
        { name: '铭雕格式', value: 'mingdiao' },
        { name: '精美格式', value: 'jingmei' },
      ],
      formData: {
        offsetAfterLabelRotation: false,
        xOffsetAfterLabelRotation: '',
        yOffsetAfterLabelRotation: '',
        labelingDodge: true,
        labelingOrder: 'cutOrder',
        label_after_send_stock: false,
        labelOverturn: false,
        labelFormat: '',
        labelXOffset: '',
        labelYOffset: '',
        labelingMachine: true,
        labelImageFormat: '',
        labelImageFormatNew: '',
        label_resolution: [],
        front_labeling_start_num: '0', //贴标序号起始数值
        stockNumOffset: 1, //大板序号补偿位数 默认1位
        label_img_big_plank_folder: false, // 标签图片根据大板分为不同文件夹
        labeling_file_insert: false, // 贴标文件插入到对应大板的贴标图片文件夹中
        label_picture_delimiter: false,
        labelingCountertopSetting: {
          labelingStartPosition: 'bottom_left',
          xDatumSwap: false,
          yDatumSwap: false,
        },
        layoutImageSetting: {
          num_auto_offset: 1,
          layoutImage: [
            {
              enable: false,
              imageDirection: 'horiz',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [158, 107],
              way: 1,
            },
            {
              enable: false,
              imageDirection: 'horiz',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [158, 107],
              way: 2,
            },
            {
              enable: false,
              imageDirection: 'vert',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [793, 1122],
              way: 3,
            },
          ],
        },
        rotateLabelSetting: {
          criticalValue: 80,
          horizontal: 'H',
          rotateLabel: false,
        },
        label_image_name_type: [], // 字段同步后端命名
        label_image_name_separator: '_', //贴标文件分隔符
        bmp_color_style: 0, //是否为1bit导出
        lable_parallel_setting: {
          parallel_plank: false,
          parallel_approach: 'parallel_height',
        },
        // 后端使用数量字段要求
        old_label_format: '',
      },
      tagList: [],
      defaulTagValue: {
        address: '订单一',
        orderNo: '220818-154',
        nc_filename: '001_多层实木_T01_18',
        labeling_filename: 'Label_001_多层实木_T01_18',
        plankNum: '6126134567891',
        stockNum: '1',
        priority: '1',
        labelId: '001',
        auto_index: '0',
      },
      isHideLabelingInsert: false, // 根据是否是默认模板展示
      autoArrangeError: '',
      tagHorSideArr: [
        { label: '与小板长度保持平行', value: 'parallel_height' },
        { label: '与小板宽度保持平行', value: 'parallel_width' },
      ],
      labelingOrderList: [
        {
          label: 'cuttingDock.labelingSettings.cuttingLabelOder',
          value: 'cutOrder',
        },
        { label: 'cuttingDock.labelingSettings.labelToUp', value: 'toTop' },
        {
          label: 'cuttingDock.labelingSettings.labelToDown',
          value: 'toBottom',
        },
      ],
      horizontalList: [
        {
          label: 'cuttingDock.labelingSettings.rotateSetting.horizontal',
          value: 'H',
        },
        {
          label: 'cuttingDock.labelingSettings.rotateSetting.vertical',
          value: 'V',
        },
        {
          label: 'cuttingDock.labelingSettings.rotateSetting.either',
          value: 'HV',
        },
      ],
      labelingStartPositions: [
        {
          label: '左上角',
          value: 'top_left',
        },
        {
          label: '左下角',
          value: 'bottom_left',
        },
        {
          label: '右上角',
          value: 'top_right',
        },
        {
          label: '右下角',
          value: 'bottom_right',
        },
      ],
    }
  },
  watch: {
    'formData.layoutImageSetting.num_auto_offset': {
      handler(newVal) {
        if (!newVal) {
          this.formData.layoutImageSetting.num_auto_offset = 1
        }
      },
      deep: true,
      immediate: true,
    },
    obj() {
      if (Object.keys(this.obj).length > 0) {
        this.formData = { ...this.obj, old_label_format: this.obj.labelFormat }
      }
      if (this.obj.label_image_in_nc_folder) {
        this.formData.label_img_big_plank_folder = false
      }
      // 大板补偿位数默认为1
      if (typeof this.obj.stockNumOffset === 'undefined') {
        this.formData.stockNumOffset = 1
      }
      this.handlerEditStockNumOffset(this.formData.stockNumOffset)
    },
    'formData.labelImageFormat': {
      handler(newVal) {
        // 在贴标图片后缀为jpg，图片编码格式为bmp时，默认将图片编码选择为png
        if (newVal === 'jpg' && this.formData.labelImageFormatNew === 'bmp') {
          this.formData.labelImageFormatNew = 'png'
        }
      },
      immediate: true,
    },
    ncLabel: {
      handler(val) {
        this.tagList = val
          .filter((v) => v.label_type == 'label_image_info')
          .map((item) => ({
            key: item.label_name,
            title: item.label_name_show,
          }))
      },
    },
    'formData.labelFormat': {
      handler(val) {
        const info = this.allLabelingList.find(
          (item) => item.labelFormat === val
        )
        // 是默认模板
        if (info && info.default_model === 1) {
          this.isHideLabelingInsert = true
          this.labeling_file_insert = false
        } else {
          this.isHideLabelingInsert = false
        }
      },
    },
    'formData.front_labeling_start_num': {
      handler(newVal) {
        if (
          this.formData.label_image_name_type.filter((i) => i === 'auto_index')
            .length
        ) {
          this.defaulTagValue.auto_index = newVal
        }
      },
      immediate: true,
    },
    'formData.yOffsetAfterLabelRotation': {
      handler(val) {
        if (val == 0) {
          this.formData.yOffsetAfterLabelRotation = ''
        }
      },
    },
    'formData.xOffsetAfterLabelRotation': {
      handler(val) {
        if (val == 0) {
          this.formData.xOffsetAfterLabelRotation = ''
        }
      },
    },
  },
  computed: {
    ...mapState(['ncLabel']),
    ...mapState('equipEnvironment', ['previewInfo']),
    labelingStartPosition() {
      const p = this.labelingStartPositions.find(
        (item) =>
          this.formData.labelingCountertopSetting.labelingStartPosition ===
          item.value
      )
      return p ? p.label : '左下角'
    },
    labelingStartPositionTip() {
      let label = this.formData.labelingCountertopSetting.labelingStartPosition
      let tip = ''
      switch (label) {
        case 'top_left':
          tip = '+x，-y'
          break
        case 'top_right':
          tip = '-x，-y'
          break
        case 'bottom_left':
          tip = '+x，+y'
          break
        case 'bottom_right':
          tip = '-x，+y'
          break
      }
      return tip
    },
    // 生成示例
    exampleName() {
      let str = this.formData.label_image_name_type
        ? Array.from(this.formData.label_image_name_type)
            .map((val) => {
              return this.defaulTagValue[val]
            })
            .join(this.formData.label_image_name_separator)
        : ''
      return str
    },
    // 是否导出bmp
    isBmpExport() {
      return (
        this.formData.labelImageFormat == 'bmp' &&
        this.formData.labelImageFormatNew == 'bmp'
      )
    },
    // 标签旋转后偏移必填检测
    checkTagRotateOffset() {
      return (
        this.formData.offsetAfterLabelRotation &&
        (!this.formData.xOffsetAfterLabelRotation ||
          !this.formData.yOffsetAfterLabelRotation)
      )
    },
    isShowBmpRadio() {
      return this.formData.labelImageFormat === 'bmp'
    },
  },
  methods: {
    // 切换选择
    handleChangeRadio(e) {
      if (e == 'toTop') {
        buryPointFunc(
          '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从下往上贴标'
        )
      } else if (e == 'toBottom') {
        buryPointFunc(
          '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从上往下贴标'
        )
      } else {
        buryPointFunc('雕刻机-贴标设置-自动贴标顺序设置-按照开料顺序贴标')
      }
    },
    // 工位起始点改变
    handleChangeStartPos(val) {
      const p = this.labelingStartPositions.find((item) => item.label === val)
      this.formData.labelingCountertopSetting.labelingStartPosition = p
        ? p.value
        : 'bottom_left'
    },
    // 标签池拖动改变
    handleTagChange(values) {
      this.formData.label_image_name_type = values
    },
    // 输入分隔符
    handleinputSplitChar() {
      // 限制输入特殊符号
      const reg = /[\\<>:|./?*"]/g
      this.formData.label_image_name_separator =
        this.formData.label_image_name_separator.replace(reg, '')
      if (this.formData.label_image_name_separator.length > 10) {
        this.formData.label_image_name_separator =
          this.formData.label_image_name_separator.slice(0, 10)
      }
    },
    // 输入贴标序号起始数值
    handleInputNumber() {
      const reg = /[^0-9]/g
      this.formData.front_labeling_start_num =
        this.formData.front_labeling_start_num.replace(reg, '')
      if (this.formData.front_labeling_start_num.length > 10) {
        this.formData.front_labeling_start_num =
          this.formData.front_labeling_start_num.slice(0, 10)
      }
    },
    // 贴标序号起始数值鼠标失去光标
    handleInputBlur() {
      if (!this.formData.front_labeling_start_num) {
        this.formData.front_labeling_start_num = '0'
      }
    },
    /**
     * @desc 自定义贴标图片名(示例) 跟随 大板序号补偿位数到 动态改变
     * @param data 大板序号补偿位数
     */
    handlerEditStockNumOffset(data) {
      if (!data) return
      // 添加埋点
      buryPoint({
        function_module: 'engraving',
        function_point: 'stockNumOffset',
      })
      let stockNum = this.defaulTagValue.stockNum
      if (this.defaulTagValue.stockNum.length < data) {
        stockNum = stockNum.padStart(data, '0')
      } else {
        stockNum = stockNum.slice(stockNum.length - data, stockNum.length)
      }
      this.defaulTagValue.stockNum = stockNum
    },
    // index为1的时候为宽的值
    paibanImgLimit(item, index) {
      item.imageSize[index] = Number(
        item.imageSize[index].replace(/^(0+)|[^\d]+/g, '')
      )
    },
    // 修改贴标机模板时
    changeLabelingFormat() {
      if (this.formData.labelFormat != '' && this.labelNotOnline) {
        this.$emit('update:labelNotOnline', false)
      }
    },
    resolutionChange(val, key) {
      this.formData.label_resolution[key] = val.replace(/[^\d]/g, '')
    },
    checkInout(val, obj, keys, rule, min) {
      verifyInputNumber(val, obj, keys, rule, false, min)
    },
    // 当旋转生效输入框未输入值时激活输入框
    focusInput() {
      this.$refs['rotateInput'].focus()
    },
  },
  mounted() {
    this.focusInput()
  },
}
</script>

<style scoped lang="less">
.labelling-setting {
  .head-title {
    display: flex;
    align-items: center;
    background: #fff;
    span {
      margin-right: 16px;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .labeling-setting {
    border-bottom: 0px;
  }
  .label-file-format {
    background: #fff;
    .radio-box {
      padding: 24px 24px 16px 24px;
      .all-labeling {
        z-index: 4;
      }
    }
    .not-online-info {
      margin-left: 16px;
      color: #333;
      color: #f00;
      font-size: 14px;
    }
  }
  .auto-feeding-box {
    padding: 24px 24px 16px 24px;
    background-color: #fff;
    .tip {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .auto-label-setting {
    margin-top: 24px;
    .title {
      padding: 24px;
    }
    .auto-label-tip {
      line-height: 31px;
    }
  }
  .tag-setting {
    background: #fff;
    .head-title {
      .tip {
        margin-right: 10px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
      }
    }
    .group {
      display: flex;
      align-items: center;
      padding: 24px 24px 16px 24px;
      .el-input {
        width: 100px;
        margin: 0 10px;
      }
      &/deep/.ant-radio-group {
        margin: 0;
      }
      img {
        margin: 0 40px;
      }
    }
  }
  .XYZ-offset {
    background: #fff;
    .XYZ-offset-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 24px;
      & > span {
        color: #8c8c8c;
        font-size: 14px;
      }
      span {
        cursor: default;
      }
      .ant-input {
        width: 83px;
      }
      .x-point {
        margin-right: 40px;
      }
      .y-point {
        margin-right: 24px;
      }
    }
  }
  .image-format {
    background: #fff;
    .image-format-box {
      padding: 24px;
      .el-input,
      .split-char {
        width: 100px;
      }
    }
    .bmp-bit-setting {
      padding-left: 30px;
      :deep(.ant-radio-group) {
        .ant-radio-wrapper {
          span:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  .paiban-image {
    background: #fff;
    .paiban-image-box {
      padding: 24px;
      > div {
        margin-bottom: 32px;
      }
    }
    .paiban-form-item {
      display: flex;
      margin-bottom: 16px;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      .title {
        display: inline-block;
        flex-shrink: 0;
        width: 92px;
        margin-right: 16px;
        text-align: left;
      }
    }
    .paiban-size {
      .ant-input {
        width: 64px;
      }
    }
  }
  .err-tip {
    top: 45px;
    left: 140px;
    color: red;
  }
}
.auto-arrange-umber {
  padding: 0 24px;
}
.labeling-dodge {
  align-items: center;
  padding: 24px;
}
#labellingSetting_autoArrangeNumber_input {
  width: 64px !important;
}
</style>
