<template>
  <div class="saw-engraving-setting">
    <div class="flex flex-cross--center">
      <!-- 锯切雕刻机 -->
      <span class="color-o-9 mr16 fs20">{{
        $t('cuttingDock.sawEngraving.enableSaw')
      }}</span>
      <a-switch
        id="engraving_setting_saw_engraving_switch"
        v-model="formData.mentaoji_enable"
        @change="handleChangeEnable"
      ></a-switch>
      <!-- 对接焊接板加工需要矩形板四边封边和总修边一样，且手动操作成单列排版 -->
      <span class="ml10 color-o-4">{{
        $t('cuttingDock.sawEngraving.dockTip')
      }}</span>
    </div>
    <div class="knives-store" v-show="formData.mentaoji_enable">
      <!-- 刀库 -->
      <span class="title">{{ $t('common.knivesStore') }}</span>
      <div class="main-table">
        <page-table
          class="mentaoji"
          :dataFrom="'mentaoji'"
          :tableConfig="tableConfig"
          :data="formData.mentaoji_saws"
          @clickRow="clickRow"
          @tableInputChange="tableInputChange"
        >
          <template #saw_direction="{ row }">
            <a-radio-group
              v-model="row.saw_direction"
              id="engraving_setting_mentaoji_saws_saw_direction_radio"
            >
              <a-radio value="H">{{ $t('cuttingDock.knives.sawHor') }}</a-radio>
              <br />
              <a-radio value="V">{{ $t('cuttingDock.knives.sawVer') }}</a-radio>
            </a-radio-group>
          </template>
          <template #cut_from="{ row }">
            <a-radio-group
              v-model="row.cut_from"
              id="engraving_setting_mentaoji_saws_cut_from_radio"
            >
              <a-radio value="0000">{{
                $t('cuttingDock.knives.towardsZero')
              }}</a-radio>
              <br />
              <a-radio value="9999">{{
                $t('cuttingDock.knives.awayFromZero')
              }}</a-radio>
            </a-radio-group>
          </template>
        </page-table>
      </div>
      <div class="operation-btn">
        <!-- 插入行 -->
        <a-button
          @click="addLineMentaoji"
          class="mr12"
          id="engraving_setting_mentaoji_saws_table_add_row"
          >{{ $t('cuttingDock.knives.insert') }}</a-button
        >
        <!-- 删除行 -->
        <a-button
          @click="deleteLineMentaoji"
          id="engraving_setting_mentaoji_saws_table_delete_row"
          class="danger-btn"
          >{{ $t('cuttingDock.knives.delete') }}</a-button
        >
      </div>
    </div>
    <div class="cut-setting" v-show="formData.mentaoji_enable">
      <!-- 切割设置 -->
      <span class="title">{{ $t('cuttingDock.sawEngraving.cutSet') }}</span>
      <div class="flex">
        <div>
          <div class="cut-setting-item">
            <!-- 锯片用途 -->
            <span class="label mr18"
              >{{ $t('cuttingDock.sawEngraving.sawUsing') }}：</span
            >
            <a-checkbox
              v-model="formData.sawToTrim"
              :disabled="formData.sawToLayout"
              @change="handleChangeSawTrim"
              >{{ $t('cuttingDock.sawEngraving.trim') }}</a-checkbox
            >
            <a-checkbox
              v-model="formData.sawToCut"
              :disabled="formData.sawToLayout"
              @change="handleChangeSawCut"
              >{{ $t('cuttingDock.sawEngraving.cutBlank') }}</a-checkbox
            >
          </div>
          <div class="cut-setting-item">
            <!-- 不生成纵向锯片切割路径 -->
            <span class="color-o-9 mr8">{{
              $t('cuttingDock.cuttingParams.unGenSawPath')
            }}</span>
            <a-checkbox v-model="formData.noVerticalSaw"></a-checkbox>
          </div>
          <div class="cut-setting-item">
            <!-- 锯片使用条件 -->
            <span class="label"
              >{{ $t('cuttingDock.sawEngraving.sawUseCond') }}：</span
            >
            <a-radio-group v-model="formData.mentaojiSizeSetting">
              <a-radio :value="false">
                {{ $t('cuttingDock.sawEngraving.hasUsing') }}
              </a-radio>
              <a-radio
                :value="true"
                :disabled="formData.sawToLayout"
                @change="handleChangeSawUse"
              >
                {{ $t('cuttingDock.cuttingParams.restSizeLimit') }}
                <a-input
                  id="engraving_setting_mentaojiSizeSetting_input"
                  v-model="formData.mentaojiSize"
                  suffix="mm"
                  class="mh8"
                  style="width: 100px"
                  @input="inputCheck('mentaojiSize', false, 1)"
                ></a-input>
                {{ $t('cuttingDock.sawEngraving.onlyUse') }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="cut-setting-item">
            <!-- 切割方式 -->
            <span class="label"
              >{{ $t('cuttingDock.sawEngraving.cutMethod') }}：</span
            >
            <a-radio-group
              v-model="formData.sawThrough"
              @change="handleChangeCutMet"
            >
              <a-radio
                :value="false"
                style="margin-right: 35px; margin-left: -1px"
              >
                {{ $t('cuttingDock.sawEngraving.oneKnifePull') }}
              </a-radio>
              <a-radio :value="true">
                {{ $t('cuttingDock.cuttingParams.restSizeLimit') }}
                <a-input
                  id="engraving_setting_mentaojiSizeSetting_input"
                  v-model="formData.sawThroughSize"
                  suffix="mm"
                  class="mh8"
                  style="width: 100px"
                  @input="inputCheck('sawThroughSize', false, 1)"
                ></a-input>
                {{ $t('cuttingDock.sawEngraving.onlyOneKnifePull') }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="cut-setting-item">
            <!-- 启用锯片厚度排版 -->
            <span class="label">{{
              $t('cuttingDock.sawEngraving.enableSawThick')
            }}</span>
            <a-switch
              v-model="formData.sawToLayout"
              @change="handleChangeThickLay"
            ></a-switch>
          </div>
          <div class="cut-setting-item" v-show="formData.sawToLayout">
            <!-- 已封好不再切的大板边 -->
            <span class="color-o-9 mr8">{{
              $t('cuttingDock.sawEngraving.edgedDir')
            }}</span>
            <a-checkbox
              class="mr20"
              v-model="formData.edgeNoSaw"
              @change="handleEnableEdge"
            ></a-checkbox>
            <div class="edge-warp">
              <span
                class="ellipsis"
                v-for="(item, index) in edgeDirArr"
                :key="index"
                @click="handleSelectEdge(item.value)"
                :id="`saw_engraving_setting_edge_${index}_btn`"
                :title="translateLang(item.label)"
                :class="{ active: formData.edgeNoSawSide == item.value }"
                >{{ $t(item.label) }}</span
              >
            </div>
          </div>
          <!-- 先切断余料的横切路径 -->
          <div class="cut-setting-item">
            <span class="color-o-9 mr8 cut-surplus-path">{{
              $t('cuttingDock.sawEngraving.generateCutSurplusPath')
            }}</span>
            <a-checkbox
              class="mr20"
              v-model="formData.firstCutSurplus"
              @change="handleChangeCutSurplus"
            ></a-checkbox>
          </div>
        </div>
        <div class="saw-img">
          <img src="@/assets/image/saw_setting.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTable from '@/components/page-table'
import { translate } from '@/util/commonFun'
import { buryPointApi, genUniId } from '@/util/commonFuncs'
import { verifyInputNumber } from '@/util/commonFuncs.js'
import { regReplaceCuttingParamsInputCheck } from '@/util/regReplace'

import { mentaojiConfig } from './config/tableConfig'

export default {
  components: {
    pageTable,
  },
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    ncExportSetting: {
      type: [Array, Object],
      default: () => ({}),
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 冻结 不可修改
      tableConfig: Object.freeze(mentaojiConfig),
      formData: {
        mentaoji_enable: false,
        // 门套机
        mentaoji_saws: [],
        // 锯切修边
        sawToTrim: true,
        // 锯片切割下料
        sawToCut: true,
        // 不生成纵向锯片切割路径
        noVerticalSaw: false,
        mentaojiSize: 20,
        mentaojiSizeSetting: true,
        // 一刀拉穿
        sawThrough: true,
        // 一刀拉穿值
        sawThroughSize: 20,
        // 启用锯片厚度排版
        sawToLayout: false,
        // 已封好不再切的大板边
        edgeNoSaw: false,
        // 封好的边
        edgeNoSawSide: 'left',
        // 启用切割余料路径
        firstCutSurplus: false,
      },
      // 门套机选中行
      mentaojiCurrentRow: {},
      // 已封好边不再切方向
      edgeDirArr: [
        { label: 'cuttingDock.sawEngraving.top', value: 'top' },
        { label: 'cuttingDock.sawEngraving.bottom', value: 'bottom' },
        { label: 'cuttingDock.sawEngraving.left', value: 'left' },
        { label: 'cuttingDock.sawEngraving.right', value: 'right' },
      ],
    }
  },
  watch: {
    obj() {
      if (Object.keys(this.obj).length > 0) {
        let s = JSON.parse(JSON.stringify(this.obj))
        this.formData = s
      }
    },
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    //门套机插入行
    addLineMentaoji() {
      const initData = {
        serial_num: this.formData.mentaoji_saws.length + 1,
        saw_width: '',
        saw_direction: 'H',
        cut_from: '0000',
        move_speed: '',
        cut_speed: '',
        start_code: '',
        end_code: '',
        saw_down_code: '',
        saw_up_code: '',
        abscissa: 'X',
        ordinate: 'Y',
        webId: genUniId(),
      }
      this.formData.mentaoji_saws.push(initData)
    },
    //门套机删除行
    deleteLineMentaoji() {
      let deleteRowIndex = this.formData.mentaoji_saws.findIndex(
        (item) => item == this.mentaojiCurrentRow
      )
      if (this.formData.mentaoji_saws.length != 0) {
        this.formData.mentaoji_saws.splice(deleteRowIndex, 1)
        // 删除数据后需要对所有的数据进行编号重新排序
        // 判断删除的数据是否是最后一个，避免多余的循环排序，删除数据是最后一个时不需要走重新编号逻辑
        if (deleteRowIndex !== this.formData.mentaoji_saws.length) {
          for (
            let index = 0;
            index < this.formData.mentaoji_saws.length;
            index++
          ) {
            this.formData.mentaoji_saws[index].serial_num = index + 1
          }
        }
      }
    },
    // 空走速度 || 加工速度 输入校验
    tableInputChange(row, prop) {
      const oldValue = row[prop] + ''
      if (prop == 'move_speed' || prop == 'cut_speed') {
        verifyInputNumber(row[prop], row, [prop], '5-3')
      }
      if (
        (prop === 'move_speed' && row[prop] == 0 && oldValue != 0) ||
        (prop === 'cut_speed' && row[prop] == 0 && oldValue != 0)
      ) {
        row[prop] = ''
      }
      if (!oldValue) {
        row[prop] = ''
      }
    },
    // 选中门套机选项
    clickRow(row) {
      this.mentaojiCurrentRow = row
    },
    // 输入检验
    inputCheck(val, type, decimal, positive, max) {
      // 只为正整数
      if (type) {
        this.formData[val] = this.formData[val].replace(/[^\d]/g, '') // 去除数字和小数点以外的数字
      } else {
        // 可以为正负数
        this.formData[val] = regReplaceCuttingParamsInputCheck(
          this.formData[val]
        )

        // 第五个参数加上是为了控制只能输入正数及小数不能输入负数,并返回，走小数限制会只能输入两位
        if (positive) {
          this.formData[val] = this.formData[val].replace(/[^\d*\.]/g, '')
          return
        }
        if (decimal) {
          this.formData[val] = this.formData[val].replace(
            /^(\-)*(\d+)\.(\d\d).*$/,
            '$1$2.$3'
          )
        }
        if (max) {
          if (this.formData[val] > max) {
            this.formData[val] = max
          }
        }
      }
    },
    handleChangeEnable() {
      if (this.formData.mentaoji_enable) {
        buryPointApi('engraving', 'enableSaw')
      }
    },
    handleChangeSawTrim() {
      buryPointApi('engraving', 'changeSawTrim')
    },
    handleChangeSawCut() {
      buryPointApi('engraving', 'changeSawCut')
    },
    handleChangeSawUse() {
      buryPointApi('engraving', 'changeSawUse')
    },
    handleChangeCutMet() {
      buryPointApi('engraving', 'changeSawCutMet')
    },
    // 启用锯片厚度排版相关处理
    handleChangeThickLay() {
      if (this.formData.sawToLayout) {
        this.formData.sawToTrim = true
        this.formData.sawToCut = true
        this.formData.mentaojiSizeSetting = false
      }
      buryPointApi('engraving', 'enableThickLay')
    },
    // 启用已封好不再切大板
    handleEnableEdge() {
      if (this.formData.edgeNoSaw) {
        buryPointApi('engraving', 'enableEdge')
      }
    },
    // 选择已封好边
    handleSelectEdge(val) {
      this.formData.edgeNoSawSide = val
    },
    // 修改是否开启切割余料路径
    handleChangeCutSurplus() {
      buryPointApi('engraving', 'genCutSurplusPath')
    },
  },
}
</script>

<style lang="less" scoped>
.saw-engraving-setting {
  padding: 10px 0 0 24px;
  .title {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
    font-size: 20px;
  }
  .knives-store {
    margin-top: 25px;
    .main-table {
      box-sizing: border-box;
      padding: 20px 0 16px 0;
      /deep/.el-table {
        &::before {
          background: transparent;
        }
        .has-gutter tr {
          background-color: #fafafa;
        }
        .el-table__cell.gutter {
          background-color: rgb(250, 250, 250);
        }
        .hidden-columns {
          display: none;
        }
        .knives_type {
          .icon-revise-show {
            display: none;
          }
          &:hover .icon-revise-show {
            display: inline-block;
          }
        }
      }
    }
    .operation-btn {
      box-sizing: border-box;
      .danger-btn {
        color: #ff4d4f;
        &.ant-btn {
          border-color: #ff4d4f;
        }
      }
    }
  }
  .cut-setting {
    margin-top: 36px;
    &-item {
      display: flex;
      align-items: center;
      margin-top: 24px;
      .label {
        display: inline-block;
        width: 112px;
        margin-right: 16px;
        color: rgba(0, 0, 0, 0.9);
      }
      .edge-warp {
        border-radius: 3px;
        > span {
          display: inline-block;
          width: 66px;
          height: 32px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 30px;
          text-align: center;
          border: 1px solid #dcdcdc;
          cursor: pointer;
          transition: all 0.5s;

          &:nth-child(1) {
            margin-right: -1px;
          }

          &:nth-child(2) {
            margin-right: -1px;
          }

          &:nth-child(3) {
            margin-right: -1px;
          }

          &:hover {
            z-index: 2;
            margin-right: 0;
            color: #18a8c7;
            border-color: #18a8c7;
          }
        }

        > span.active {
          z-index: 2;
          margin-right: 0;
          color: #18a8c7;
          border-color: #18a8c7;
        }
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .saw-img {
      margin-top: 75px;
    }
  }
}
</style>
