<template>
  <div class="aio-machine-setting h100">
    <div class="h100 y-auto">
      <div class="box flex flex-cross--center">
        <span class="title fs20 color-0">{{
          $t('cuttingDock.aioMachineSetting.outputFile')
        }}</span>
        <a-switch
          default-checked
          v-model="formData.enable_integration_device"
          id="engraving_setting_aioMachine_enable_integration_device_switch"
        ></a-switch>
        <i style="margin-left: 20px; color: #f00">{{
          $t('cuttingDock.aioMachineSetting.outputFileTips')
        }}</i>
      </div>
      <div class="box flex" v-if="formData.enable_integration_device">
        <span class="title">{{
          $t('cuttingDock.aioMachineSetting.fileType')
        }}</span>
        <el-select
          v-model="formData.yitiji_machine_name"
          :placeholder="$t('cuttingDock.aioMachineSetting.select')"
          size="small"
          class="select-warp"
          :popper-append-to-body="false"
          filterable
          id="engraving_setting_aioMachine_yitiji_machine_name_select"
          @change="handleChangeOneMac"
        >
          <el-option
            v-for="(item, index) in aioOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :id="`engraving_setting_aioMachine_yitiji_machine_name_option_${index}`"
            class="select-option"
          >
          </el-option>
        </el-select>
        <div class="ml20" v-show="isJidong">
          <div>
            <!-- 对接需开启以下功能 -->
            {{ t('openFunction') }}
          </div>
          <div>
            <!-- 开启雕刻机 -->
            1、{{ t('openCarving') }}
            <span style="color: #f00">
              <!-- XY轴互换功能 -->
              {{ t('xyReverseFunction') }}
            </span>
          </div>
          <div>
            <!-- 雕刻机工位起始点设置为 -->
            2、{{ t('carvingStartSet') }}
            <span style="color: #f00">
              <!-- 右上角 -->
              {{ t('rightTop') }}
            </span>
          </div>
          <div>
            <!-- 雕刻机正面再修边值调整为 -->
            3、{{ t('carvingEdgeSet') }}
            <span style="color: #f00">
              <!-- 与总修边值相同 -->
              {{ t('finalEdgeSame') }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="box"
        v-if="isDiameterShow && formData.enable_integration_device"
      >
        <span class="title">{{
          $t('cuttingDock.aioMachineSetting.diameter')
        }}</span>
        <a-input
          id="engraving_trimmingKnifeDiameter_input"
          v-model="formData.trimming_knife_diameter"
          placeholder=""
          @input="handleInputChange"
        />
        mm
      </div>
      <div class="com_code" v-show="formData.enable_integration_device">
        <span class="mr10">
          <!--显示数据样例  -->
          {{ t('showDataTemp') }}
        </span>
        <a-checkbox v-model="formData.is_display_sample" />
        <div
          class="mt20 bolder flex flex-dir--top"
          v-if="formData.is_display_sample"
          id="engraving_isDisplaySample_checkbox"
        >
          <span class="bold color-0 fs16">
            <!-- 数据样例 -->
            {{ t('dataTemp') }}
          </span>
          <DockFiveSixSample
            :file-type="fileType"
            :ariMachine="true"
            :modelSetting="modelSetting"
          />
        </div>
      </div>
      <div
        v-show="
          formData.enable_integration_device &&
          (formData.process_anc_in_front || formData.process_anc_in_back)
        "
      >
        <div class="operation-btn flex flex-cross--center mt20">
          <a-button
            @click="handleAddNewKnife"
            class="mr12"
            id="aio_setting_knife_table_add_row"
          >
            <!-- 插入行 -->
            {{ kt('insert') }}
          </a-button>
          <a-button
            @click="handleDeleteKnife"
            class="danger-btn"
            id="aio_setting_knife_table_delete_row"
          >
            <!-- 删除行 -->
            {{ kt('delete') }}
          </a-button>
        </div>
        <div class="main-table mt20">
          <el-table
            :data="formData.anc_setting.knives"
            :row-style="changeStyle"
            :cell-style="columnStyle"
            :header-cell-style="headerStyle"
            :header-row-style="headerRowStyle"
            @row-click="choseKnife"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
            @cell-click="cellClick"
            :max-height="340"
            class="w100"
          >
            <el-table-column
              v-for="(knife, indexs) in knivesColumns"
              :key="indexs + '1'"
              :label="$t(knife.label)"
              :prop="knife.props"
              :width="knife.width"
              :fixed="[0, 1, 2].includes(indexs)"
            >
              <template slot-scope="scope">
                <div v-if="knife.props === 'use'" class="knife-purpose">
                  <a-checkbox-group v-model="scope.row.use">
                    <a-checkbox
                      value="holeable"
                      class="mb10 mr8"
                      :id="`engraving_setting_knife_table_${scope.row.index}_use_holeable_checkbox`"
                    >
                      <span
                        style="margin: 0"
                        :id="`engraving_setting_knife_table_${scope.row.index}_use_holeable_checkbox_label`"
                      >
                        <!-- 打孔 -->
                        {{ t('drillHole') }}
                      </span>
                    </a-checkbox>
                    <a-checkbox
                      value="slotable"
                      :id="`engraving_setting_knife_table_${scope.row.index}_use_slotable_checkbox`"
                    >
                      <span
                        style="margin: 0"
                        :id="`engraving_setting_knife_table_${scope.row.index}_use_slotable_checkbox_label`"
                      >
                        <!-- 拉槽 -->
                        {{ t('pullTrough') }}
                      </span>
                    </a-checkbox>
                  </a-checkbox-group>
                </div>
                <div v-else-if="knife.props == 'cutable'">
                  <a-checkbox
                    v-model="scope.row[knife.props]"
                    @change="onlyOneChecked(scope.row)"
                    :id="`engraving_setting_knife_table_${scope.row.index}_cutable_checkbox`"
                  ></a-checkbox>
                </div>
                <div
                  v-else-if="
                    scope.row.index === rowIndex &&
                    scope.column.index === columnIndex &&
                    knife.props !== 'cutable' &&
                    knife.props !== 'use'
                  "
                >
                  <el-input
                    v-model="scope.row[knife.props]"
                    ref="editInput"
                    type="textarea"
                    size="mini"
                    resize="none"
                    autosize
                    @blur="inputBlur(scope.row, knife.props)"
                    @change="inputBlur(scope.row, knife.props)"
                    @input="
                      handleInputCheck(scope.row, knife.props, knife.isCheck)
                    "
                    :id="`engraving_setting_knife_table_${scope.row.index}_${knife.props}_input`"
                  />
                </div>
                <div
                  class="knives_type"
                  style="white-space: pre-line"
                  v-else-if="
                    knife.props !== 'desc' &&
                    knife.props !== 'cutable' &&
                    knife.props !== 'use'
                  "
                  :id="`engraving_setting_knife_table_${scope.row.index}_${knife.props}`"
                >
                  {{ scope.row[knife.props] }}
                  <span
                    :class="[
                      'iconfont',
                      'icon-revise',
                      { 'icon-revise-show': scope.row[knife.props] },
                    ]"
                    style="color: #18a8c7"
                  ></span>
                </div>
                <div class="knives_type" style="white-space: pre-line" v-else>
                  {{ scope.row[knife.props] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="text-area flex mt20">
          <div class="mr20 flex flex-dir--top">
            <span>
              <!-- 程序开始代码 -->
              {{ t('beginCode') }}：
            </span>
            <a-textarea
              v-model="formData.anc_setting.anc_begin_code"
              id="aio_setting_oddBeginCode_textarea"
              placeholder="输入程序开始代码"
              :maxLength="200"
              style="width: 380px; height: 100px"
            ></a-textarea>
            <div class="textarea-length align-right">
              {{ formData.oddBeginCode?.length ?? 0 }}/200
            </div>
          </div>
          <div class="flex flex-dir--top">
            <span>
              <!-- 程序结束代码 -->
              {{ t('endCode') }}：
            </span>
            <a-textarea
              v-model="formData.anc_setting.anc_end_code"
              id="engraving_setting_evenBeginCode_textarea"
              placeholder="输入程序结束代码"
              :maxLength="200"
              style="width: 380px; height: 100px"
            ></a-textarea>
            <div class="textarea-length align-right">
              {{ formData.evenBeginCode?.length ?? 0 }}/200
            </div>
          </div>
        </div>
      </div>

      <!-- 使用所有一体机的设置 -->
      <div v-show="formData.enable_integration_device" class="common-setting">
        <!-- 孔槽避让夹手位置 -->
        <div class="duck-clamp-hand">
          <div class="item">
            <NewContent
              ><span class="label">{{
                $translateLang('孔槽避让夹手位置')
              }}</span></NewContent
            >
            <a-switch
              v-model="formData.aio_fixture_setting.aio_avoid_fixture"
              @change="handleChangeclampHandStatus"
            />
          </div>
          <div
            v-show="
              formData.aio_fixture_setting.aio_avoid_fixture ||
              engravSwitchCloseAlwaysShow
            "
          >
            <div class="item">
              <span class="label">{{ $translateLang('夹手长度(L)') }}</span>
              <div class="form-item">
                <a-input
                  v-model="formData.aio_fixture_setting.aio_fixture_length"
                  suffix="mm"
                />
              </div>
            </div>
            <div class="item">
              <span class="label">{{ $translateLang('夹手宽度(W)') }}</span>
              <div class="form-item">
                <a-input
                  v-model="formData.aio_fixture_setting.aio_fixture_width"
                  suffix="mm"
                />
              </div>
            </div>
            <div class="item">
              <span class="label">{{ $translateLang('夹手1距边(H1)') }}</span>
              <div class="form-item">
                <a-input
                  v-model="
                    formData.aio_fixture_setting.aio_first_fixture_height
                  "
                  suffix="mm"
                />
              </div>
            </div>
            <div class="item">
              <span class="label">{{ $translateLang('夹手2距边(H2)') }}</span>
              <div class="form-item">
                <a-input
                  v-model="
                    formData.aio_fixture_setting.aio_second_fixture_height
                  "
                  suffix="mm"
                />
              </div>
            </div>
            <div class="item">
              <span class="label">{{ $translateLang('夹手方向') }}</span>
              <div class="table-board-radio">
                <span
                  :class="{
                    'active-radio':
                      formData.aio_fixture_setting.aio_fixture_direction ===
                      item.value,
                  }"
                  class="ellipsis"
                  v-for="(item, index) in lavePoints"
                  :key="index"
                  @click="handeeChangeClamlHandPosition(item.value)"
                  :id="`engraving_setting_lavePoint_${index}_btn`"
                  >{{ $translateLang(item.label) }}</span
                >
              </div>
            </div>
            <!-- 示例图 -->
            <div class="example-img">
              <img
                :src="
                  require(`@/assets/image/clampHand${formData.aio_fixture_setting.aio_fixture_direction}.png`)
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { translate } from '@/util/commonFun'
import { buryPointApi, genUniId } from '@/util/commonFuncs'
import {
  regReplaceBaseMaterialDefaultInputCheck,
  regReplaceCuttingDockInputCheck,
  regReplaceCuttingParamsDiameter,
} from '@/util/regReplace'
import DockFiveSixSample from '@/views/equipment/component/dockFiveSixSample.vue'

import NewContent from '../newContent.vue'

// 夹手方向map映射
const clampHandDirectionMap = new Map([
  ['左侧', 'L'],
  ['右侧', 'R'],
  ['上侧', 'T'],
  ['下侧', 'B'],
])

export default {
  components: {
    DockFiveSixSample,
    NewContent,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        enable_integration_device: false,
        yitiji_machine_name: 'xinhongtian',
        trimming_knife_diameter: 0,
        is_display_sample: false,
        anc_setting: {
          anc_begin_code: '',
          anc_end_code: '',
          knives: [],
        },
        process_anc_in_front: false,
        process_anc_in_back: false,
        has_anc_setting: false,
        aio_fixture_setting: {
          aio_avoid_fixture: false, // 避让一体机夹手
          aio_fixture_length: 0, //夹手长度
          aio_fixture_width: 0, // 夹手宽度
          aio_first_fixture_height: 0, // 夹手1距边(H1)
          aio_second_fixture_height: 0, // 夹手2距边(H2)
          aio_fixture_direction: clampHandDirectionMap.get('左侧'), // L 左 R 右 T 上 B 下
        },
      },
      knives: [],
      aioOptions: [
        { label: '鑫宏天一体机', value: 'xinhongtian' },
        { label: '品脉一体机', value: 'pinmai' },
        { label: '锐诺一体机', value: 'ruinuo' },
        { label: '凯鸿一体机', value: 'kaihong' },
        { label: '极东一体机', value: 'jidong' },
        { label: '南兴菲达一体机', value: 'nanxingfeida' },
      ],
      knivesColumns: [
        // 编号
        {
          label: 'cuttingDock.knives.number',
          props: 'name',
          width: 70,
          isCheck: false,
        },
        // 刀直径Φ
        {
          label: 'cuttingDock.knives.diameter',
          props: 'diameter',
          width: 90,
          isCheck: false,
        },
        // 用途
        {
          label: 'cuttingDock.knives.use',
          props: 'use',
          width: 160,
          isCheck: false,
        },
        // Z轴快速移动速度
        {
          label: 'cuttingDock.knives.moveZF',
          props: 'moveZF',
          width: 150,
          isCheck: true,
        },
        // Z轴加工速度
        {
          label: 'cuttingDock.knives.cutZF',
          props: 'cutZF',
          width: 110,
          isCheck: true,
        },
        // XY轴快速移动速度
        {
          label: 'cuttingDock.knives.moveF',
          props: 'moveF',
          width: 150,
          isCheck: true,
        },
        // XY轴加工速度
        {
          label: 'cuttingDock.knives.cutF',
          props: 'cutF',
          width: 120,
          isCheck: true,
        },
        // X坐标偏移
        {
          label: 'cuttingDock.knives.xOffset1',
          props: 'xOffset',
          width: 100,
          isCheck: true,
        },
        // Y坐标偏移
        {
          label: 'cuttingDock.knives.yOffset1',
          props: 'yOffset',
          width: 100,
          isCheck: true,
        },
        // Z坐标偏移
        {
          label: 'cuttingDock.knives.zOffset1',
          props: 'zOffset',
          width: 100,
          isCheck: true,
        },
        // 换刀开始代码
        {
          label: 'cuttingDock.knives.startCode',
          props: 'startCode',
          width: 120,
          isCheck: false,
        },
        // 换刀结束代码
        {
          label: 'cuttingDock.knives.stopCode',
          props: 'stopCode',
          width: 120,
          isCheck: false,
        },
      ],
      rowIndex: null,
      columnIndex: null,
      currentRow: {},
      modelSetting: {},
      fileType: '',
      lavePoints: [
        {
          label: '左侧',
          value: clampHandDirectionMap.get('左侧'),
        },
        {
          label: '右侧',
          value: clampHandDirectionMap.get('右侧'),
        },
        {
          label: '上侧',
          value: clampHandDirectionMap.get('上侧'),
        },
        {
          label: '下侧',
          value: clampHandDirectionMap.get('下侧'),
        },
      ],
    }
  },
  watch: {
    obj: {
      handler() {
        const copyObj = JSON.parse(JSON.stringify(this.obj))
        if (!copyObj.hasOwnProperty('is_display_sample')) {
          copyObj.is_display_sample = false
        }
        copyObj.old_yitiji_machine_name = copyObj.yitiji_machine_name
        this.knives = copyObj.anc_setting.knives
        copyObj.anc_setting.knives = []
        copyObj.process_anc_in_front = false
        copyObj.process_anc_in_back = false
        this.formData = copyObj
        this.getOneMacSet()
      },
      deep: true,
    },
  },
  computed: {
    isDiameterShow() {
      return this.formData.yitiji_machine_name == 'ruinuo'
    },
    isJidong() {
      return this.formData.yitiji_machine_name == 'jidong'
    },
    engravSwitchCloseAlwaysShow() {
      return this.$store.state.preferencesSetting.setting
        .engravSwitchCloseAlwaysShow
    },
  },
  methods: {
    handleInputChange() {
      this.formData.trimming_knife_diameter =
        regReplaceBaseMaterialDefaultInputCheck(
          this.formData.trimming_knife_diameter,
          'engraving'
        )
      this.formData.trimming_knife_diameter =
        this.formData.trimming_knife_diameter.indexOf('.') > 0
          ? this.formData.trimming_knife_diameter
              .split('.')[0]
              .substring(0, 2) +
            '.' +
            this.formData.trimming_knife_diameter.split('.')[1]
          : this.formData.trimming_knife_diameter.substring(0, 2)
      this.formData.trimming_knife_diameter =
        this.formData.trimming_knife_diameter - 0
    },
    // 获取所有一体机模板
    getAllAioMachineFile() {
      this.$getByToken('/aio/models', { from: 'user' }, (res) => {
        if (res.status == 1) {
          this.aioOptions = res.data.model_list.map((item) => ({
            ...item,
            label: item.model_name,
            value: item.aio_format,
          }))
        }
      })
    },
    // 获取指定一体机设置
    getOneMacSet(changeModel) {
      let knives = []
      let beginCode = ''
      let endCode = ''
      this.formData.anc_setting.knives = []
      if (
        ![
          'xinhongtian',
          'pinmai',
          'ruinuo',
          'kaihong',
          'jidong',
          'nanxingfeida',
        ].includes(this.formData.yitiji_machine_name)
      ) {
        this.$getByToken(
          `/aio/models/${this.formData.yitiji_machine_name}`,
          {},
          (res) => {
            if (res.status == 1) {
              this.formData.process_anc_in_front =
                res.data.model_setting.anc_setting.process_anc_in_front
              this.formData.process_anc_in_back =
                res.data.model_setting.anc_setting.process_anc_in_back
              this.modelSetting = res.data.model_setting
              this.fileType = this.formData.is_display_sample
                ? this.formData.yitiji_machine_name
                : ''
              // 后台anc设置开启 且不是切换模板即初始调用
              const { process_anc_in_front, process_anc_in_back } =
                res.data.model_setting.anc_setting
              const isFlag = process_anc_in_front || process_anc_in_back
              if (!changeModel && isFlag) {
                knives = this.knives
                beginCode = this.formData.anc_setting.anc_begin_code
                endCode = this.formData.anc_setting.anc_end_code
              }
              // 后台anc设置开启 且 切换模板 就用模板的默认设置
              if (isFlag && changeModel) {
                knives = res.data.model_setting.model_aio_knives
                beginCode = res.data.model_setting.anc_setting.anc_begin_code
                endCode = res.data.model_setting.anc_setting.anc_end_code
              }
              this.formData.anc_setting.anc_begin_code = beginCode
              this.formData.anc_setting.anc_end_code = endCode
              if (knives && Object.keys(knives).length) {
                Object.keys(knives)?.forEach((key) => {
                  // 打孔 拉槽 复选框处理
                  let useArr = []
                  if (knives[key].holeable && knives[key].slotable) {
                    useArr = ['holeable', 'slotable']
                  } else if (knives[key].holeable) {
                    useArr = ['holeable']
                  } else if (knives[key].slotable) {
                    useArr = ['slotable']
                  } else {
                    useArr = []
                  }
                  this.formData.anc_setting.knives.push({
                    ...knives[key],
                    use: useArr,
                    webId:
                      knives[key]?.webId ||
                      (this.knives && this.knives[key]?.webId) ||
                      genUniId(),
                  })
                })
              }
            }
          }
        )
      } else {
        this.formData.anc_setting = {
          anc_begin_code: '',
          anc_end_code: '',
          knives: [],
        }
        this.formData.process_anc_in_front = false
        this.fileType = this.formData.is_display_sample
          ? this.formData.yitiji_machine_name
          : ''
      }
    },
    // 切换一体机模板
    handleChangeOneMac() {
      this.getOneMacSet(true)
    },
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改单元格样式
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px'
      }
    },
    // 更改表头单元格样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa'
      }
      return 'background: #fafafa'
    },
    // 更改表头行样式
    headerRowStyle({ row, rowIndex }) {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    // 点击单元格切换成输入框
    cellClick(row, column, cell, event) {
      if (column.property == 'id' || column.property == 'desc') {
        return
      }
      this.rowIndex = row.index
      this.columnIndex = column.index
      if (column.property != 'use' && column.property != 'cutable') {
        this.$nextTick(() => {
          this.$refs['editInput'][0].focus()
        })
      }
    },
    // 修改表格中的值
    inputBlur(row, prop) {
      row['desc'] = row['name']
      this.rowIndex = -1
      this.columnIndex = -1
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
      if (['startCode2', 'stopCode2'].includes(prop)) {
        buryPointApi(
          'equipment_dock',
          prop == 'startCode2' ? 'start_code2' : 'stop_code2'
        )
      }
    },
    // 添加刀具
    handleAddNewKnife() {
      this.formData.anc_setting.knives.push({
        name: '',
        diameter: '',
        use: [],
        moveZF: '',
        cutZF: '',
        moveF: '',
        cutF: '',
        xOffset: '',
        yOffset: '',
        zOffset: '',
        startCode: '',
        stopCode: '',
        webId: genUniId(),
      })
      this.rowIndex = this.formData.anc_setting.knives.length - 1
      this.columnIndex = 0
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    // 删除刀具
    handleDeleteKnife() {
      this.formData.anc_setting.knives.splice(
        this.formData.anc_setting.knives.findIndex(
          (v) => v.index == this.currentRow.index
        ),
        1
      )
    },
    // 选中刀具
    choseKnife(row, column, event) {
      this.currentRow = row
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    // 只能有一把刀选中开料
    onlyOneChecked(row) {
      if (row.cutable == false) {
        this.formData.anc_setting.knives.forEach((v) => {
          v.cutable = false
        })
        row.cutable = true
      }
      if (row.cutable == true) {
        this.formData.anc_setting.knives.forEach((v) => {
          v.cutable = false
        })
        row.cutable = true
      }
    },
    handleInputCheck(row, prop, flag) {
      row['desc'] = row['name']
      if (flag) {
        row[prop] = regReplaceCuttingDockInputCheck(row[prop])
      }
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      }
    },
    /** 一体机设置部分翻译 */
    t(k) {
      return translate('cuttingDock.aioMachineSetting.' + k)
    },
    /** 切割机刀部分的翻译 */
    kt(k) {
      return translate('cuttingDock.knives.' + k)
    },
    // 夹手位置修改
    handeeChangeClamlHandPosition(val) {
      this.formData.aio_fixture_setting.aio_fixture_direction = val
    },
    // 修改夹手位置避让状态
    handleChangeclampHandStatus(val) {
      // 开启时添加埋点，记录开启人数
      if (val) {
        buryPoint({
          function_module: 'engraving',
          function_point: 'clampHandAvoid',
        })
      }
    },
  },
  mounted() {
    this.getAllAioMachineFile()
  },
}
</script>

<style lang="less" scoped>
.aio-machine-setting {
  padding: 15px;
  // margin: 16px;
  background: #fff;
  .box {
    margin-bottom: 20px;
    .title {
      margin-right: 15px;
    }
    .ant-input {
      width: 100px;
    }
  }
  .main-table {
    box-sizing: border-box;
    /deep/.el-table {
      &::before {
        background: transparent;
      }
      .has-gutter tr {
        background-color: #fafafa;
      }
      .el-table__cell.gutter {
        background-color: rgb(250, 250, 250);
      }
      .hidden-columns {
        display: none;
      }
      .knives_type {
        .icon-revise-show {
          display: none;
        }
        &:hover .icon-revise-show {
          display: inline-block;
        }
      }
    }
  }
  .operation-btn {
    box-sizing: border-box;
    .danger-btn {
      color: #ff4d4f;
      &.ant-btn {
        border-color: #ff4d4f;
      }
    }
  }
  .knife-purpose {
    display: flex;
    flex-wrap: wrap;
    /deep/.ant-checkbox-wrapper {
      margin-left: 0;
      &:nth-child(3) {
        margin-right: 8px;
      }
    }
    /deep/.ant-checkbox + span {
      padding-right: 0;
    }
  }
  .common-setting {
    .duck-clamp-hand {
      margin: 20px 0;
      padding-bottom: 20px;
      position: relative;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .label {
          width: 120px;
          text-align: right;
          margin-right: 5px;
          &::after {
            content: ':';
            color: #000;
          }
        }
        .table-board-radio {
          display: flex;

          span {
            display: inline-block;
            width: 74px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
              z-index: 2;
              color: #18a8c7;
              border-color: #18a8c7;
            }

            &:nth-child(1) {
              margin-right: -1px;
              border-radius: 4px 0 0 4px;
            }

            &:nth-child(2) {
              margin-right: -1px;
            }

            &:nth-child(3) {
              margin-right: -1px;
            }

            &:nth-child(4) {
              border-radius: 0 4px 4px 0;
            }
          }

          .active-radio {
            z-index: 2;
            color: #18a8c7;
            border-color: #18a8c7;
          }
        }
      }
      .example-img {
        position: absolute;
        top: 50px;
        right: 50%;
      }
    }
  }
}
</style>
<style lang="less">
.aio-machine-setting {
  /deep/ .el-select-dropdown .el-popper {
    z-index: 1 !important;
  }
}
</style>
