var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"paring-off",attrs:{"id":"paring-off"}},[_c('span',{staticClass:"head-title inline-block pl0"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.edgeSetting'))+" "),(_setup.props.isShowSwitch)?_c('a-switch',{staticStyle:{"margin-left":"165px"},attrs:{"default-checked":"","id":"trim_setting_switch"},model:{value:(_setup.trimmingSetting[_setup.propertyName.TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.TRIM, $$v)},expression:"trimmingSetting[propertyName.TRIM]"}}):_vm._e(),(_setup.props.isShowSwitch)?_c('span',{staticClass:"fs14 ml20 span-text"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.offTip')))]):_vm._e()],1),(_setup.trimmingSetting[_setup.propertyName.TRIM] || !_setup.props.isShowSwitch || _vm.isShowSecondaryCatalog)?_c('div',{staticClass:"paring-off-setting-img"},[_c('div',{staticClass:"base-item"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.doubleTrim'))+"：")]),_c('a-radio-group',{on:{"change":function($event){return _setup.handleChangeDoubleTrim(_setup.trimmingSetting[_setup.propertyName.IS_DOUBLE_PANEL_FOUR_TRIM], 'imgName')}},model:{value:(_setup.trimmingSetting[_setup.propertyName.IS_DOUBLE_PANEL_FOUR_TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.IS_DOUBLE_PANEL_FOUR_TRIM, $$v)},expression:"trimmingSetting[propertyName.IS_DOUBLE_PANEL_FOUR_TRIM]"}},[_c('a-radio',{attrs:{"value":false,"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.trimTwoSide'))+" ")]),_c('a-radio',{attrs:{"value":true,"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.trimFourSide'))+" ")])],1)],1),_c('div',{staticClass:"base-item mt5 flex"},[_c('span',{staticClass:"base-title-label pt7"},[_vm._v(_vm._s(_setup.trimmingSetting[_setup.propertyName.IS_DOUBLE_PANEL_FOUR_TRIM] ? _vm.$t('cuttingDock.cuttingParams.cylinderDir') : _vm.$t('cuttingDock.cuttingParams.edgeCutDirection'))+"：")]),_c('div',{staticStyle:{"width":"376px"}},[_c('div',{staticClass:"table-board-radio p0 flex-cross--center flex-wrap--wrap inline-block"},_vm._l((_setup.paringPointList),function(item,index){return _c('span',{key:index,staticClass:"table-board-span inline-block align-center w90 h38",class:{
              'is-not-allowed':
                _setup.propertyName.TRIM in _setup.trimmingSetting
                  ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                  : false,
              'active-radio':
                _setup.trimmingSetting[_setup.propertyName.TRIM_SIDE] == item.value,
            },attrs:{"id":`trim_setting_parDir_${index}_btn`,"title":_setup.translateLang( item.label )},on:{"click":function($event){return _setup.handleParDir(item.value)}}},[_vm._v(_vm._s(_setup.translateLang( item.label )))])}),0),_c('div',{staticClass:"flex flex-dir--top",staticStyle:{"margin-top":"24px"}},[_c('span',{staticClass:"color-o-4 trim-dir-tip"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.rightInmShow'))+"，"),_c('span',{staticClass:"trim-line-img"}),_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.currentTrimDir'))+"，"),_c('span',{staticClass:"cylinder-img"}),_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.cylinderTip')))]),_c('a-checkbox-group',{directives:[{name:"show",rawName:"v-show",value:(!_setup.trimmingSetting[_setup.propertyName.IS_DOUBLE_PANEL_FOUR_TRIM]),expression:"!trimmingSetting[propertyName.IS_DOUBLE_PANEL_FOUR_TRIM]"}],on:{"change":_setup.handleChangeOnlyTrim},model:{value:(_setup.onlyTrimValue),callback:function ($$v) {_setup.onlyTrimValue=$$v},expression:"onlyTrimValue"}},[_c('a-checkbox',{attrs:{"value":"long","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                    ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                    : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.onlyLong'))+" ")]),_c('a-checkbox',{attrs:{"value":"short","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                    ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                    : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.onlyShort'))+" ")])],1)],1)]),_c('div',{staticClass:"paring-off-img"},[_c('img',{class:{
            'is-not-allowed':
              _setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false,
          },attrs:{"src":_setup.paringImg(_setup.trimmingSetting[_setup.propertyName.TRIM_SIDE], _setup.imgName),"alt":""}})])]),_c('div',{staticClass:"base-item"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.backCutOrder'))+"：")]),_c('a-radio-group',{attrs:{"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
            ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
            : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.TRIM_L_MOMENT]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.TRIM_L_MOMENT, $$v)},expression:"trimmingSetting[propertyName.TRIM_L_MOMENT]"}},[_c('a-radio',{attrs:{"value":"切割开始","id":"trim_setting_trimLMoment_start_radio"}},[_c('span',{attrs:{"id":"trim_setting_trimLMoment_start_radio_label"}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.backFirst'))+" ")])]),_c('a-radio',{attrs:{"value":"切割结束","id":"trim_setting_trimLMoment_end_radio"}},[_c('span',{attrs:{"id":"trim_setting_trimLMoment_end_radio_label"}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.backSecond'))+" ")])])],1)],1),_c('div',{staticClass:"base-item flex"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.frontReCut'))+"：")]),_c('div',[_c('a-input',{staticStyle:{"width":"138px"},attrs:{"suffix":"mm","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false,"id":"trim_setting_secondTrimValue_input"},on:{"blur":_setup.inputCheck},model:{value:(_setup.trimmingSetting[_setup.propertyName.SECOND_TRIM_VALUE]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.SECOND_TRIM_VALUE, $$v)},expression:"trimmingSetting[propertyName.SECOND_TRIM_VALUE]"}}),_c('div',{staticClass:"color-o-4 fs14 mt4"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.frontReCutTip')))])],1)]),_c('div',{staticClass:"base-item flex"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.useNewCut'))+"：")]),_c('div',[_c('a-switch',{attrs:{"id":"trim_setting_new_trim_switch","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.NEW_TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.NEW_TRIM, $$v)},expression:"trimmingSetting[propertyName.NEW_TRIM]"}}),_c('div',{staticClass:"color-o-4 fs14 mt4"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.newCutTip')))])],1)]),_c('div',{staticClass:"base-item"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.singleMachineLimit'))+"：")]),_c('a-switch',{attrs:{"id":"trim_setting_single_trim_switch","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
            ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
            : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL, $$v)},expression:"trimmingSetting[propertyName.IS_FIRST_TRIM_SINGLE_PANEL]"}})],1),(_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL] || _vm.isShowSecondaryCatalog)?_c('div',[_c('div',{staticClass:"base-item"},[_c('span',{staticClass:"base-title-label"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.trim'))+"：")]),_c('a-radio-group',{attrs:{"disabled":!_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL]},on:{"change":function($event){return _setup.handleChangeDoubleTrim(_setup.trimmingSetting[_setup.propertyName.IS_SINGLE_PANEL_FOUR_TRIM],'singleImgName')}},model:{value:(_setup.trimmingSetting[_setup.propertyName.IS_SINGLE_PANEL_FOUR_TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.IS_SINGLE_PANEL_FOUR_TRIM, $$v)},expression:"trimmingSetting[propertyName.IS_SINGLE_PANEL_FOUR_TRIM]"}},[_c('a-radio',{attrs:{"value":false,"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.trimTwoSide'))+" ")]),_c('a-radio',{attrs:{"value":true,"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false}},[_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.trimFourSide'))+" ")])],1)],1),(!_setup.trimmingSetting[_setup.propertyName.IS_SINGLE_PANEL_FOUR_TRIM])?_c('div',{staticClass:"base-item mt8 flex",attrs:{"id":`singlePanelTrim_${_setup.props.source}`}},[_c('span',{staticClass:"base-title-label pt7"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.edgeCutDirection'))+"：")]),_c('div',{staticStyle:{"width":"376px"}},[_c('div',{staticClass:"table-board-radio p0 flex-cross--center flex-wrap--wrap inline-block"},_vm._l((_setup.paringPointList),function(item,index){return _c('span',{key:index,staticClass:"table-board-span inline-block align-center w90 h38",class:{
              'active-radio':
                _setup.trimmingSetting[_setup.propertyName.SINGLE_PANEL_TRIM_DIRECTION] ==
                item.value,
              'is-not-allowed':
                (_setup.propertyName.TRIM in _setup.trimmingSetting
                    ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                      : false) ||
                !_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL],
            },on:{"click":function($event){return _setup.handleClickDirection(item.value)}}},[_vm._v(_vm._s(_setup.translateLang(item.label)))])}),0),_c('div',{staticClass:"flex flex-dir--top",staticStyle:{"margin-top":"24px"}},[_c('span',{staticClass:"color-o-4 trim-dir-tip"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.rightInmShow'))+"，"),_c('span',{staticClass:"trim-line-img"}),_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.currentTrimDir'))+"，"),_c('span',{staticClass:"cylinder-img"}),_vm._v(" "+_vm._s(_vm.$t('cuttingDock.cuttingParams.cylinderTip')))])])]),_c('div',{staticClass:"paring-off-img"},[_c('img',{class:{
            'is-not-allowed':
              _setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false,
          },attrs:{"src":_setup.paringImg(_setup.trimmingSetting[_setup.propertyName.SINGLE_PANEL_TRIM_DIRECTION], _setup.singleImgName),"alt":""}})])]):_vm._e()]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }