import { render, staticRenderFns } from "./codeGroup.vue?vue&type=template&id=30f9e002&scoped=true&"
import script from "./codeGroup.vue?vue&type=script&setup=true&lang=ts&"
export * from "./codeGroup.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./codeGroup.vue?vue&type=style&index=0&id=30f9e002&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f9e002",
  null
  
)

export default component.exports